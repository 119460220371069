import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styles from "./Stepper.module.css";

function Stepper(props) {
  return (
    <div className={styles["stepper-container"]}>
      {props.steps?.map((step, index) => (
        <div
          key={index}
          className={`${styles["step-item"]} ${
            props.currentStep === index + 1 && `${styles.active}`
          } ${index + 1 < props.currentStep && `${styles.complete}`}`}
        >
          <div className={styles.step}>
            {props.currentStep > index + 1 ? (
              <>
                <FontAwesomeIcon icon={faCheck} />
              </>
            ) : (
              `${index + 1}`
            )}
          </div>
          <p className={styles["step-name"]}>{step}</p>
        </div>
      ))}
    </div>
  );
}

export default Stepper;
