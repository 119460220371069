import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";
import LoadingButton from "../../components/Button/LoadingButton";
import ToggleSwitch from "../../components/Switch/ToggleSwitch";

import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BACKEND_URL_CONFIG } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import styles from "./CreateProject.module.css";
import {
  convertedSplitterChars,
  initPreprocessConfig,
  languagesOptions,
  lemmatizerOptions,
  preprocessInformationsObject,
  tokenizerOptions,
} from "./constants";

// Dear developer,
// To handle with multiple toggle switch (preprocessing boolean config),
// we decided to create a referential dict for all useRef objects.
function getAllRefs(preprocessConfigObj) {
  const allRefsObj = {};
  Object.keys(preprocessConfigObj).forEach((key) => {
    allRefsObj[key] = useRef(false);
  });
  return allRefsObj;
}

function CreateProject(props) {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [userTokenizerChoice, setUserTokenizerChoice] = useState(undefined);
  const [userLemmatizerChoice, setUserLemmatizerChoice] = useState(undefined);
  const [inputProjectName, setInputProjectName] = useState("");
  const [shouldDisplaySeperator, setShouldDisplaySeperator] = useState(false);
  const [shouldDisplayLemmatizer, setShouldDisplayLemmatizer] = useState(
    initPreprocessConfig["lemmatize_process"]
  );

  const [preprocessConfig, setPreprocessConfig] =
    useState(initPreprocessConfig);
  // We create a JS object to store all preprocess config with useRefs methods.
  const allInputRefs = getAllRefs(preprocessConfig);
  const splitterCharsRef = useRef(null);
  const languagesListRef = useRef(0);
  const tokenizerListRef = useRef(null);
  const lemmatizerListRef = useRef(null);
  const popupInformationRef = useRef([]);

  // useEffect to handle the dependency between tokenizer_engine and splitter_char_list
  useEffect(() => {
    const isManualTokenizer = userTokenizerChoice === "manual";
    setShouldDisplaySeperator(isManualTokenizer);
    // Clear lemmatizer value because tokenizer has been changed
    lemmatizerListRef?.current?.clearValue();
  }, [userTokenizerChoice]);

  // useEffect to handle the dependency between lemmatize_process switch button
  // and lemmatize_engine input field
  useEffect(() => {
    if (preprocessConfig["lemmatize_process"]) {
      setShouldDisplayLemmatizer(true);
      return;
    }
    setShouldDisplayLemmatizer(false);
    return;
  }, [preprocessConfig]);

  const projectNameHandler = (e) => {
    setInputProjectName(e.target.value);
  };

  const updatePreprocessConfig = (preprocessTypeName) => {
    const refObj = allInputRefs[preprocessTypeName];
    setPreprocessConfig({
      ...preprocessConfig,
      [preprocessTypeName]: refObj.current.checked,
    });
  };

  const popupInformationHandler = (index) => {
    const currentDisplayMode = popupInformationRef.current[index].style.display;
    if (currentDisplayMode === "flex") {
      popupInformationRef.current[index].style.display = null;
      return;
    }
    popupInformationRef.current[index].style.display = "flex";
  };

  const popupInformationClose = (index) => {
    popupInformationRef.current[index].style.display = null;
  };

  const createProjectHandler = async () => {
    // Check project name is empty or not
    if (inputProjectName === "") {
      toast.error("Project name can not be empty.");
      return;
    }
    // Check if tokenizer is set correctly
    if (userTokenizerChoice === undefined) {
      toast.error("Please choose a tokenizer engine.");
      return;
    }
    // Check if lemmatizer is set correctly
    if (preprocessConfig["lemmatize_process"]) {
      if (userLemmatizerChoice === undefined) {
        toast.error("Please choose a lemmatizer engine.");
        return;
      }
    }

    const structuredSplitterChars = splitterCharsRef.current
      ?.getValue()
      ?.map((item) => item.value);

    const structuredLanguagesList = languagesListRef.current
      ?.getValue()
      ?.map((item) => item.value);

    const payload = {
      bucket_name: "c-topic-master",
      project_name: inputProjectName,
      languages_list: structuredLanguagesList,
      tokenizer_engine: userTokenizerChoice,
      lemmatizer_engine: userLemmatizerChoice,
      splitter_chars: structuredSplitterChars,
      ...preprocessConfig,
    };

    setLoading(true);
    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.createProject,
        payload,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setLoading(false);
        toast.success(response.data.msg);
        props.setProjectName(response.data.project_name);

        // If all good, we pass to next step
        props.setCurrentStep((prev) => prev + 1);
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast.error(err.response.data.msg);
        return;
      }
      toast.error("An error occured while creating new project.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles["creation-project-form"]}>
        <div className={styles["title-content"]}>
          <img
            src={process.env.PUBLIC_URL + "/img/lancement.png"}
            className={styles["create-project-image"]}
            alt=""
          ></img>
          <h2>Configure your new project</h2>
        </div>
        <div className={styles["create-form-container"]}>
          <div className={styles["project-name-container"]}>
            <label className={styles.label}>Project name</label>
            <input
              type="text"
              name="projectName"
              required="required"
              placeholder="Enter project name here"
              onChange={projectNameHandler}
              value={inputProjectName}
            />
          </div>
          <div className={styles["multi-select-wrapper"]}>
            <label className={styles.label}>Select languages</label>
            <Select
              ref={languagesListRef}
              closeMenuOnSelect={true}
              options={languagesOptions}
              defaultValue={{ value: "fr", label: "French" }}
            />
          </div>
          <div className={styles["multi-select-wrapper"]}>
            <label className={styles.label}>Select tokenizer</label>
            <Select
              isClearable
              ref={tokenizerListRef}
              closeMenuOnSelect={true}
              onChange={(el) => setUserTokenizerChoice(el?.value)}
              options={tokenizerOptions}
              placeholder="Select your tokenizer..."
            />
          </div>
          {shouldDisplaySeperator && (
            <div className={styles["multi-select-wrapper"]}>
              <label className={styles.label}>Select your seperators</label>
              <CreatableSelect
                ref={splitterCharsRef}
                isMulti
                options={convertedSplitterChars}
                defaultValue={convertedSplitterChars}
              />
            </div>
          )}
          {shouldDisplayLemmatizer && (
            <div className={styles["multi-select-wrapper"]}>
              <label className={styles.label}>Select lemmatizer</label>
              <Select
                isClearable
                ref={lemmatizerListRef}
                closeMenuOnSelect={true}
                options={lemmatizerOptions.filter((item) =>
                  item.compatible.includes(userTokenizerChoice)
                )}
                onChange={(el) => setUserLemmatizerChoice(el?.value)}
                placeholder="Select your lemmatizer..."
              />
            </div>
          )}
          <div className={styles["project-preprocess-config-wrapper"]}>
            {Object.entries(preprocessConfig)?.map(
              ([configKey, configValue], index) => (
                <div key={index} className={styles["toggle-list-wrapper"]}>
                  <span
                    style={{
                      position: "relative",
                    }}
                  >
                    <div
                      ref={(el) => (popupInformationRef.current[index] = el)}
                      className={styles["popup-description"]}
                    >
                      {preprocessInformationsObject[configKey].description}
                    </div>
                    <FontAwesomeIcon
                      icon={faCircleQuestion}
                      className={styles["description-icon"]}
                      onMouseEnter={() => popupInformationHandler(index)}
                      onMouseLeave={() => popupInformationClose(index)}
                    ></FontAwesomeIcon>
                  </span>
                  <div className={styles["toggle-wrapper"]}>
                    <span
                      style={{
                        position: "relative",
                      }}
                    >
                      {preprocessInformationsObject[configKey].displayName}
                    </span>
                    <ToggleSwitch
                      inputRef={allInputRefs[configKey]}
                      checked={configValue}
                      onChange={() => updatePreprocessConfig(configKey)}
                    ></ToggleSwitch>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        <div className={styles.footer}>
          {loading ? (
            <LoadingButton>Creating...</LoadingButton>
          ) : (
            <Button onClick={createProjectHandler}>Create project</Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateProject;
