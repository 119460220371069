import {
  faEllipsisVertical,
  faExclamationCircle,
  faLock,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";
import LoadingButton from "../../components/Button/LoadingButton";
import Tag from "../../components/Tag/Tag";
import { BACKEND_URL_CONFIG } from "../../config";
import { db } from "../../firebase/firebase";
import { useAuth } from "../../hooks/useAuth";
import { persistor } from "../../store";
import { projectActions } from "../../store/project-slice";
import styles from "./VersionsOverview.module.css";

function VersionsOverview() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [versionsInfoArray, setVersionsInfoArray] = useState([]);
  const versionNameRef = useRef([]);
  const navigate = useNavigate();
  const urlParams = useParams();
  const dropdownMenuRef = useRef([]);
  const dispatch = useDispatch();

  const dropdownMenuHandler = (index) => {
    const currentDisplayMode = dropdownMenuRef.current[index].style.display;
    if (currentDisplayMode === "flex") {
      dropdownMenuRef.current[index].style.display = null;
      return;
    }
    dropdownMenuRef.current[index].style.display = "flex";
  };

  const deleteVersionHandler = async (index) => {
    setLoading(true);
    const payload = {
      project_name: projectName,
      version_name: versionNameRef.current[index].innerHTML,
    };

    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.deleteVersion,
        payload,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(`${payload.version_name} is deleted successfully.`);
        setLoading(false);
      }
    } catch (err) {
      toast.error("An error occured while deleting version.");
      setLoading(false);
    }
  };

  const lockVersionHandler = async (index) => {
    // If the user want to lock the version, we lock with his email address
    // And then, there will be only him who can have access to this version
    // until he/she unlocks the version
    const versionName = versionNameRef.current[index].innerHTML;
    const docRef = doc(db, "projects", projectName);
    await updateDoc(docRef, {
      [`versions.${versionName}.locked_by`]: currentUser.email,
    });
    await updateDoc(docRef, {
      [`versions.${versionName}.locked`]: true,
    });
  };

  const unlockVersionHandler = async (index) => {
    /* eslint-disable no-restricted-globals */
    if (
      confirm(
        "If you unlock the version, all persisted data will be purged. Are you sure ?"
      )
    ) {
      try {
        const versionName = versionNameRef.current[index].innerHTML;
        const docRef = doc(db, "projects", projectName);
        await updateDoc(docRef, {
          [`versions.${versionName}.locked_by`]: null,
        });
        const response = await updateDoc(docRef, {
          [`versions.${versionName}.locked`]: false,
        });

        // Finally, if the user unlocks his version, so we purge all persisted data in redux store.
        await persistor.purge();

        dispatch(
          projectActions.deleteVersion({
            projectName: urlParams.projectName,
            versionName: versionName,
          })
        );

        return response;
      } catch {
        toast.error(
          "An error occured while unlocking the version. Persisted data is not purged."
        );
      }
    }
  };

  const createVersionButtonHandler = () => {
    navigate(`/createVersion/${projectName}`);
  };

  const goToProjectHandler = (index, lockedBy) => {
    const versionName = versionNameRef.current[index].innerHTML;
    if (lockedBy === currentUser.email) {
      navigate(`/project/${projectName}/${versionName}`);
    }
    if (lockedBy === null) {
      lockVersionHandler(index).then((result) => {
        navigate(`/project/${projectName}/${versionName}`);
      });
    }
  };

  useEffect(() => {
    // Save project name coming from URI into state
    setProjectName(urlParams.projectName);
  }, []);

  useEffect(() => {
    setLoading(true);
    // Connectin to db
    const projectsRef = collection(db, "projects");
    const docRef = doc(projectsRef, urlParams.projectName);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      const { project_name, versions } = doc.data() || {};
      if (versions === undefined) {
        toast.error(`The project ${urlParams.projectName} does not exist !`);
        navigate("/");
      }
      if (versions) {
        const versionInfos = Object.entries(versions).map(
          ([key, eachVersion]) => {
            const creationDateTimestamp = eachVersion.creation_date * 1000;
            const lastEditDateTimestamp = eachVersion.last_edit * 1000;
            const convertedDateObj = new Date(creationDateTimestamp);
            const formattedCreationDate = convertedDateObj.toLocaleDateString(
              "en-US",
              {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            );
            const formattedLastEditDate = new Date(
              lastEditDateTimestamp
            ).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            });

            return {
              projectName: project_name,
              versionName: key,
              locked: eachVersion.locked,
              lockedBy: eachVersion.locked_by,
              creationDate: formattedCreationDate,
              lastEditDate: formattedLastEditDate,
              timestamp: eachVersion.creation_date,
            };
          }
        );
        setVersionsInfoArray(
          versionInfos.sort(
            (a, b) => Number(b.convertedDateObj) - Number(a.convertedDateObj)
          )
        );
        setLoading(false);
      }
    });
    return unsubscribe;
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles["title-wrapper"]}>
          <span className={styles.title}>Versions</span>
          <div className={styles["action-buttons-wrapper"]}>
            {loading ? (
              <LoadingButton />
            ) : (
              <Button onClick={createVersionButtonHandler}>New version</Button>
            )}
          </div>
        </div>
        <div className={styles["version-overview-container"]}>
          {versionsInfoArray.length === 0 ? (
            <div className={styles["empty-table-wrapper"]}>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{ color: "#a60909", fontSize: "40px" }}
              />
              <span className={styles["info-message-wrapper"]}>
                There is no versions. Please create new one.
              </span>
            </div>
          ) : (
            <>
              <div className={styles["table-container"]}>
                <table id={styles["version-table"]}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Version name</th>
                      <th>Project name</th>
                      <th>Locked by</th>
                      <th>Created at</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {versionsInfoArray.map((doc, index) => (
                      <tr key={index} className={styles.row}>
                        <th>{index + 1}</th>
                        <th
                          ref={(el) => (versionNameRef.current[index] = el)}
                          onClick={() =>
                            goToProjectHandler(index, doc.lockedBy)
                          }
                          style={{
                            cursor: "pointer",
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          {doc.versionName}
                        </th>
                        <th
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          {doc.projectName}
                        </th>
                        <th>{doc.lockedBy ? doc.lockedBy : "-"}</th>
                        <th>{doc.creationDate}</th>
                        <th>
                          {doc.locked ? (
                            <Tag bgColor="black" fontSize="15px">
                              <FontAwesomeIcon icon={faLock} />
                            </Tag>
                          ) : (
                            <Tag bgColor="green" fontSize="15px">
                              <FontAwesomeIcon icon={faUnlock} />
                            </Tag>
                          )}
                        </th>
                        {(currentUser.email === doc.lockedBy ||
                          doc.lockedBy === null) && (
                          <th>
                            <div
                              className={styles["dropdown-menu"]}
                              onClick={() => dropdownMenuHandler(index)}
                            >
                              <div
                                ref={(el) =>
                                  (dropdownMenuRef.current[index] = el)
                                }
                                className={styles["dropdown-content"]}
                              >
                                <div
                                  onClick={() => deleteVersionHandler(index)}
                                  className={styles["dropdown-item"]}
                                  style={{ color: "red" }}
                                >
                                  Delete
                                </div>
                                {doc.locked ? (
                                  <div
                                    onClick={() => unlockVersionHandler(index)}
                                    className={styles["dropdown-item"]}
                                  >
                                    Unlock
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => lockVersionHandler(index)}
                                    className={styles["dropdown-item"]}
                                  >
                                    Lock
                                  </div>
                                )}
                              </div>
                              <button className={styles["action-button"]}>
                                <FontAwesomeIcon icon={faEllipsisVertical} />
                              </button>
                            </div>
                          </th>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default VersionsOverview;
