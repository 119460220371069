import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import AddButton from "../../components/Button/AddButton";
import DeleteButton from "../../components/Button/DeleteButton";
import Pagination from "../../components/Pagination/Pagination";
import { useProject } from "../../hooks/useProject";
import { projectActions } from "../../store/project-slice";
import TokenItem from "../WhitelistBrowser/TokenItem";
import styles from "./BlacklistBrowser.module.css";

function BlacklistBrowser(props) {
  const { topicName, projectName, versionName } = useProject();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTokens, setSelectedTokens] = useState([]);
  const matchTypeRef = useRef(0);
  const inputUserRef = useRef(0);
  const allSelectedInputRef = useRef(0);
  const dispatch = useDispatch();

  const totalItemNumber = props.topicObj.blacklistedTokens.length;
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTokensArray = [...props.topicObj.blacklistedTokens]
    .reverse()
    .slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const checkboxChangeHandler = (e) => {
    dispatch(
      projectActions.selectAllTokens({
        topicName: props.topicObj.topicName,
        projectName: projectName,
        versionName: versionName,
        tokenType: props.tokenType,
        isSelected: e.target.checked,
      })
    );
  };

  const addKeywordToTopic = (token, matchType) => {
    // Replace all space with one space
    const normalizedToken = token.replace(/\s{1,}/g, " ").toLowerCase();
    if (normalizedToken === "" || normalizedToken === " ") {
      return;
    }

    dispatch(
      projectActions.addKeywordToTopic({
        topicName: topicName,
        projectName: projectName,
        versionName: versionName,
        tokenType: "blacklist",
        newTokenObj: {
          item: normalizedToken,
          matchType: matchType ? matchType : null,
          count: null,
          selected: false,
        },
      })
    );

    // Clean input
    inputUserRef.current.value = "";
  };

  const deleteKeywordFromTopic = () => {
    selectedTokens.forEach((eachToken) => {
      dispatch(
        projectActions.deleteKeywordFromTopic({
          projectName: projectName,
          versionName: versionName,
          topicName: topicName,
          tokenToDel: eachToken.item,
          tokenType: "blacklist",
        })
      );
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // get if the token is STR or REGEX
      let matchTypeToAdd = matchTypeRef.current.value;
      let tokenToAdd = inputUserRef.current.value;

      // Shortcuts
      if (tokenToAdd.startsWith('"') && tokenToAdd.endsWith('"')) {
        matchTypeToAdd = "WORD";
        tokenToAdd = tokenToAdd.replaceAll('"', "");
      } else if (tokenToAdd.startsWith('r"') && tokenToAdd.endsWith('"')) {
        matchTypeToAdd = "REGEX";
        tokenToAdd = tokenToAdd.replace('r"', "");
        tokenToAdd = tokenToAdd.replace('"', "");
      }

      // Insert token into state
      addKeywordToTopic(tokenToAdd, matchTypeToAdd);

      // Clean input
      inputUserRef.current.value = "";
    }
  };

  const clickSaveHandler = () => {
    // get if the token is STR or REGEX
    const matchTypeToAdd = matchTypeRef.current.value;
    const tokenToAdd = inputUserRef.current.value;

    // Insert token into state
    addKeywordToTopic(tokenToAdd, matchTypeToAdd);
  };

  useEffect(() => {
    if (
      props.topicObj.blacklistedTokens.every((tokenData) => tokenData.selected)
    ) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [props.topicObj.blacklistedTokens]);

  useEffect(() => {
    setSelectedTokens(
      props.topicObj.blacklistedTokens.filter((tokenData) => tokenData.selected)
    );
  }, [props.topicObj.blacklistedTokens]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span className={styles["title-text"]}>
          {props.contentBoxTitle} - {selectedTokens.length} of {totalItemNumber}
        </span>
        {selectedTokens.length > 0 && (
          <DeleteButton
            onClick={deleteKeywordFromTopic}
            className={styles["custom-delete-button"]}
          />
        )}
        <input
          ref={allSelectedInputRef}
          className={styles["all-selected-checkbox"]}
          type="checkbox"
          checked={isAllSelected}
          onChange={checkboxChangeHandler}
        />
      </div>
      <div className={styles["input-wrapper"]}>
        <div className={styles["input-row"]}>
          <AddButton onClick={clickSaveHandler} />
          <input
            ref={inputUserRef}
            type="text"
            placeholder="Type your new token to blacklist..."
            className={styles["match-type-input-text"]}
            onKeyDown={handleKeyDown}
          />
          <select
            defaultValue="STRING"
            ref={matchTypeRef}
            className={styles["match-type-selector"]}
            name="matchType"
          >
            <option value="STRING">STR</option>
            <option value="WORD">WORD</option>
            <option value="REGEX">REGEX</option>
          </select>
        </div>
      </div>
      <div className={styles.content}>
        {currentTokensArray.map((tokenObj, index) => (
          <TokenItem
            topicObj={props.topicObj}
            tokenObj={tokenObj}
            tokenType={props.tokenType}
            topicName={props.topicObj.topicName}
            key={index}
          ></TokenItem>
        ))}
      </div>
      <div className={styles.footer}>
        <Pagination
          currentPage={currentPage}
          totalItemsNumber={totalItemNumber}
          itemsPerPage={itemsPerPage}
          paginate={paginate}
        />
      </div>
    </div>
  );
}

export default BlacklistBrowser;
