import axios from "axios";
import { doc, updateDoc } from "firebase/firestore";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteButton from "../../components/Button/DeleteButton";
import EditButton from "../../components/Button/EditButton";
import EnterButton from "../../components/Button/EnterButton";
import SaveButton from "../../components/Button/SaveButton";
import { BACKEND_URL_CONFIG } from "../../config";
import { db } from "../../firebase/firebase";
import { useAuth } from "../../hooks/useAuth";
import { useProject } from "../../hooks/useProject";
import { projectActions } from "../../store/project-slice";
import styles from "./TopicItem.module.css";

function Topic(props) {
  const { currentUser } = useAuth();
  const { projectName, versionName, versionData } = useProject();
  const [editEnabled, setEditEnabled] = useState(false);
  const topicNameRef = React.useRef(null);
  const topicLabelRef = React.useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const newTopicNameRef = useRef();
  const topicTagRef = useRef();
  const detailButtonHandler = () => {
    navigate(`topics/${props.topicName}`);
  };

  const editButtonHandler = () => {
    setEditEnabled(true);
  };

  const updateTopicLabel = async () => {
    const newTopicsObj = versionData.topics;
    // If the new topic name and old topic name are the same, do nothing
    if (newTopicNameRef.current.value === props.topicInfo.label) {
      setEditEnabled(false);
      return;
    }

    // If the new topic name already exists, we raise en error
    if (newTopicsObj[newTopicNameRef.current.value]) {
      toast.error("This topic already exists.");
      setEditEnabled(false);
      return;
    }

    // Update topic label
    const docRef = doc(db, "projects", projectName);
    updateDoc(docRef, {
      [`versions.${versionName}.topics.${props.topicName}.label`]:
        newTopicNameRef.current.value,
    });

    setEditEnabled(false);
  };

  const updateTopicTag = async () => {
    // If the new tag and old tag are the same, do nothing
    if (topicTagRef.current.value === props.topicInfo.tag) {
      setEditEnabled(false);
      return;
    }

    // Update topic label
    const docRef = doc(db, "projects", projectName);
    updateDoc(docRef, {
      [`versions.${versionName}.topics.${props.topicName}.tag`]:
        topicTagRef.current.value,
    });

    setEditEnabled(false);
  };

  const saveButtonHandler = async () => {
    updateTopicLabel();
    updateTopicTag();
  };

  const newTopicNameChangeHandler = (event) => {
    if (event.key === "Enter") {
      saveButtonHandler();
    }
  };

  const topicTagChangeHandler = (event) => {
    if (event.key === "Enter") {
      saveButtonHandler();
    }
  };

  const deleteTopicHandler = async (topicNameToDel) => {
    const payload = {
      project_name: projectName,
      version_name: versionName,
      topic_name: topicNameToDel,
    };
    props.setLoading(true);

    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.deleteTopic,
        payload,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(
          projectActions.deleteTopic({
            topicToDel: topicNameToDel,
            projectName: projectName,
            versionName: versionName,
          })
        );
        props.setLoading(false);
        toast.success(`${topicNameToDel} is deleted successfully.`);
      }
    } catch (err) {
      toast.error("An error occured while deleting a topic.");
    }
  };

  return (
    <div className={styles["topic-container"]}>
      <div ref={topicNameRef} className={styles["topic-name"]}>
        {editEnabled ? (
          <input
            type="text"
            defaultValue={props.topicInfo.label}
            ref={newTopicNameRef}
            onKeyDown={newTopicNameChangeHandler}
          />
        ) : (
          <>{props.topicInfo.label}</>
        )}
      </div>
      <div ref={topicLabelRef} className={styles["topic-labels"]}>
        {editEnabled ? (
          <input
            type="text"
            placeholder="Type your tag"
            defaultValue={props.topicInfo.tag}
            ref={topicTagRef}
            onKeyDown={topicTagChangeHandler}
          />
        ) : (
          props.topicInfo.tag && (
            <div className={styles["label-container"]}>
              {props.topicInfo.tag}
            </div>
          )
        )}
      </div>
      <div className={styles["icon-wrapper"]}>
        {editEnabled ? (
          <SaveButton onClick={saveButtonHandler} />
        ) : (
          <>
            <EnterButton
              title="Work on this topic"
              onClick={detailButtonHandler}
            />
            <EditButton
              title="Edit topic name and label"
              onClick={editButtonHandler}
            />
            <DeleteButton
              title="Delete topic"
              className={styles["custom-delete-button"]}
              onClick={() => deleteTopicHandler(props.topicName)}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default Topic;
