import axios from "axios";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";
import LoadingButton from "../../components/Button/LoadingButton";
import ToggleSwitch from "../../components/Switch/ToggleSwitch";
import { BACKEND_URL_CONFIG } from "../../config";
import { db } from "../../firebase/firebase";
import { useAuth } from "../../hooks/useAuth";
import { projectActions } from "../../store/project-slice";
import styles from "./CreateVersion.module.css";

function CreateVersion() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState("");
  const [projectName, setProjectName] = useState("");
  const [versionName, setVersionName] = useState("");
  const [sourceVersionName, setSourceVersionName] = useState("");
  const [needDuplicateVersion, setNeedDuplicateVersion] = useState(false);
  const [switchDisabled, setSwitchDisabled] = useState(false);
  const [existingVersionsObj, setExistingVersionsObj] = useState([]);
  const duplicateVersionSwitchRef = useRef(0);
  const urlParams = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const versionNameHandler = (e) => {
    setVersionName(e.target.value);
  };

  const duplicateVersionChanges = () => {
    setNeedDuplicateVersion(duplicateVersionSwitchRef.current.checked);
  };

  const createVersionHandler = async () => {
    if (versionName === "") {
      toast.error("Version name can not be empty.");
      return;
    }
    var payload = {
      project_name: projectName,
    };

    if (needDuplicateVersion) {
      var url = BACKEND_URL_CONFIG.duplicateVersion;
      payload.source_version_name = sourceVersionName;
      payload.new_version_name = versionName;
    } else {
      var url = BACKEND_URL_CONFIG.createVersion;
      payload.version_name = versionName;
    }

    setLoading(true);
    setLoadingType("Creating");
    try {
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      });
      if (response.status === 200) {
        setLoading(false);
        toast.success(response.data.msg);
        // We received success code, so we trigger process version func
        triggerProcessVersion(response.data.version_name);
      }
    } catch (err) {
      toast.error(err.response.data.msg);
      setLoading(false);
    }
  };

  const triggerProcessVersion = async (versionNameToProcess) => {
    const payload = {
      project_name: projectName,
      version_name: versionNameToProcess,
    };

    setLoading(true);
    setLoadingType("Processing");
    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.processVersion,
        payload,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        // save unclassifed verbatims list
        dispatch(
          projectActions.addUnclassifiedVerbatims({
            projectName: projectName,
            versionName: versionNameToProcess,
            unclassifiedRawVerbatims: response.data.unclassified_raw_verbatims,
            unclassifiedCleanedVerbatims:
              response.data.unclassified_cleaned_verbatims,
          })
        );

        setLoading(false);
        toast.success(response.data.msg);
        // If all good, we go again to versions overview page
        navigate(`/versions/${projectName}`);
      }
    } catch (err) {
      toast.error(err.response.data.msg);
      setLoading(false);
    }
  };

  const sourceVersionChanges = (e) => {
    const selectedSourceName = e.target.value;
    if (selectedSourceName !== "") {
      setSourceVersionName(selectedSourceName);
    }
  };

  useEffect(() => {
    // Save project name coming from URI into state object
    setProjectName(urlParams.projectName);

    // Get existing version data from firebase
    const fetchData = async () => {
      const docRef = doc(db, "projects", urlParams.projectName);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const versionObj = data.versions;
        setExistingVersionsObj(versionObj);
        // If there is any version, we disable our switch
        setSwitchDisabled(Object.keys(versionObj).length === 0);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={styles["create-version-wrapper"]}>
      <div className={styles.container}>
        <div className={styles["creation-version-form"]}>
          <div className={styles["title-content"]}>
            <img
              src={process.env.PUBLIC_URL + "/img/version_icon.png"}
              className={styles["create-version-image"]}
              alt=""
            ></img>
            <h2>Name your new version</h2>
          </div>
          <div className={styles["create-version-form-container"]}>
            <div className={styles["version-name-container"]}>
              <input
                type="text"
                name="projectName"
                required="required"
                placeholder="Enter version name here"
                onChange={versionNameHandler}
                value={versionName}
              />
            </div>
            <div className={styles["toggle-wrapper"]}>
              <span>Duplicate from existing version</span>
              <ToggleSwitch
                inputRef={duplicateVersionSwitchRef}
                onChange={duplicateVersionChanges}
                disabled={switchDisabled}
              ></ToggleSwitch>
            </div>
            {needDuplicateVersion && (
              <div className={styles["duplicate-versions-select-wrapper"]}>
                <select
                  name="existing-versions"
                  className={styles["version-select"]}
                  id="version-select"
                  onChange={sourceVersionChanges}
                >
                  <option value="">Please choose a version</option>
                  {Object.keys(existingVersionsObj).map(
                    (existingVersion, index) => (
                      <option key={index} value={existingVersion}>
                        {existingVersion}
                      </option>
                    )
                  )}
                </select>
              </div>
            )}
          </div>
          <div className={styles.footer}>
            {loading ? (
              <LoadingButton>{loadingType}...</LoadingButton>
            ) : needDuplicateVersion ? (
              <Button onClick={createVersionHandler}>Duplicate version</Button>
            ) : (
              <Button onClick={createVersionHandler}>Create version</Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateVersion;
