import { faDownload, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";
import LoadingButton from "../../components/Button/LoadingButton";
import Tag from "../../components/Tag/Tag";
import { BACKEND_URL_CONFIG } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import { useProject } from "../../hooks/useProject";
import styles from "./ExportTopics.module.css";

export default function ExportTopics({ topicsData, setModalEnabled }) {
  const [loading, setLoading] = useState(false);
  const { projectName, versionName } = useProject();
  const { currentUser } = useAuth();

  const topicItemRef = useRef([]);
  const allSelectRef = useRef(false);

  const selectRowHandler = (e, idx) => {
    if (e.target !== topicItemRef.current[idx]) {
      topicItemRef.current[idx].checked = !topicItemRef.current[idx].checked;
    }
  };

  const selectAllHandler = (e) => {
    for (let i = 0; i < topicItemRef.current.length; i++) {
      topicItemRef.current[i].checked = e.target.checked;
    }
  };

  const exportTopicsRequestHandler = async () => {
    const filteredSelectedTopics = topicItemRef.current
      ?.filter((topicItem) => topicItem.checked)
      .map((topicItem) => topicItem.name);

    if (filteredSelectedTopics.length === 0) {
      toast.error("Please select the topics you wish to download beforehand.");
      return;
    }

    const payload = {
      project_name: projectName,
      version_name: versionName,
      topic_list: filteredSelectedTopics,
    };

    setLoading(true);
    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.exportTopics,
        payload,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        const convertedJSON = JSON.stringify(
          response.data.topic_informations_dict
        );
        // Convert our json object into a blob object in order to download it
        const blob = new Blob([convertedJSON], {
          type: "application/json",
        });
        const a = document.createElement("a");
        document.body.appendChild(a);
        const blobUrl = window.URL.createObjectURL(blob);
        a.href = blobUrl;
        a.download = "topics.json";
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);

        toast.success("Topic(s) downloaded successfully");
      }
    } catch (err) {
      toast.error("An error occured while downloading topics.");
    } finally {
      setLoading(false);
      setModalEnabled(false);
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={styles["close-button-wrapper"]}
        onClick={() => setModalEnabled(false)}
      >
        <FontAwesomeIcon icon={faXmark} />
      </div>
      <div className={styles.title}>Export topic(s)</div>
      <div className={styles["action-buttons-wrapper"]}>
        <div className={styles["select-all-button"]}>
          <input
            ref={allSelectRef}
            onChange={selectAllHandler}
            className={styles["topic-item-checkbox"]}
            type="checkbox"
          ></input>
        </div>
        <div className="download-button">
          {loading ? (
            <LoadingButton />
          ) : (
            <Button onClick={exportTopicsRequestHandler}>
              <FontAwesomeIcon
                icon={faDownload}
                className={styles["download-file-icon"]}
              />
            </Button>
          )}
        </div>
      </div>
      <div className={styles.content}>
        {topicsData?.map((topicObj, index) =>
          Object.entries(topicObj).map(([topicName, topicInfo]) => (
            <div
              className={styles["topic-item-container"]}
              key={index}
              onClick={(el) => selectRowHandler(el, index)}
            >
              <div className={styles["topic-item-checkbox-wrapper"]}>
                <input
                  ref={(el) => (topicItemRef.current[index] = el)}
                  name={topicName}
                  className={styles["topic-item-checkbox"]}
                  type="checkbox"
                ></input>
              </div>
              <div className={styles["topic-item"]}>{topicInfo.label}</div>
              <Tag color="black" bgColor="#d6ecc7">
                {topicInfo.tag ? topicInfo.tag : "-"}
              </Tag>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
