import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";
import GoBackButton from "../../components/Button/GoBackButton";
import LoadingButton from "../../components/Button/LoadingButton";
import { BACKEND_URL_CONFIG } from "../../config";
import AssistantChatGPT from "../../containers/Assistant/AssistantChatGPT";
import BlacklistBrowser from "../../containers/BlacklistBrowser/BlacklistBrowser";
import NgramList from "../../containers/NgramStats/NgramList";
import VerbatimBrowser from "../../containers/VerbatimBrowser/VerbatimBrowser";
import WhitelistBrowser from "../../containers/WhitelistBrowser/WhitelistBrowser";
import { useAuth } from "../../hooks/useAuth";
import { useProject } from "../../hooks/useProject";
import { projectActions } from "../../store/project-slice";
import styles from "./TopicView.module.css";

function TopicView() {
  const { currentUser } = useAuth();
  const { projectName, versionName, topicName, projectStats, versionData } =
    useProject();
  const dispatch = useDispatch();
  const [saveLoading, setSaveLoading] = useState(false);
  const [blacklistProcessLoading, setBlacklistProcessLoading] = useState(false);
  const [whitelistProcessLoading, setWhitelistProcessLoading] = useState(false);
  const [assistantRequested, setAssistantRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const reduxTopicObj = useSelector((state) => {
    const project = state.project[projectName];

    if (project) {
      const version = project[versionName];

      if (version && version.allTopics) {
        return version.allTopics.find(
          (item) => item && item.topicName === topicName
        );
      }
    }
    return null;
  });

  const blacklistProcess = async (structuredBlacklistArray) => {
    // We structure our state. We put all blacklisted items obj into array.
    // setBlacklistArray(structuredBlacklistArray);
    const payload = {
      project_name: projectName,
      version_name: versionName,
      topic_name: topicName,
      blacklist: structuredBlacklistArray,
    };
    setBlacklistProcessLoading(true);

    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.processTopicBlacklist,
        payload,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("The blacklist is processed successfully.");
        setBlacklistProcessLoading(false);
      }
    } catch (err) {
      toast.error("An error occured while processing blacklist.");
      setLoading(false);
    }
  };

  const whitelistProcess = async (whitelistArray) => {
    const payload = {
      project_name: projectName,
      version_name: versionName,
      topic_name: topicName,
      whitelist: whitelistArray,
    };
    setWhitelistProcessLoading(true);

    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.processTopicWhitelist,
        payload,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("The whitelist is processed successfully.");
        setWhitelistProcessLoading(false);
      }
    } catch (err) {
      toast.error("An error occured while processing whitelist.");
      setLoading(false);
    }
  };

  const saveTopicHandler = () => {
    // Dear developer,
    // Before save all changes coming from Redux Store, we should check
    // if another process is about to running.
    if (
      versionData.topics[topicName].compute_status.blacklist !== "PROCESSED" ||
      versionData.topics[topicName].compute_status.whitelist !== "PROCESSED" ||
      versionData.topics[topicName].compute_status.stats !== "PROCESSED"
    ) {
      toast.error(
        "A background process is presently in progress. Please await its completion."
      );
      return;
    }

    // If there is any process running
    setSaveLoading(true);

    // Get whitelisted and blacklisted tokens array
    const structuredWhitelistArray = reduxTopicObj?.attributedTokens;
    const structuredBlacklistArray = reduxTopicObj?.blacklistedTokens;

    // process blacklist
    blacklistProcess(structuredBlacklistArray);

    // process whitelist
    whitelistProcess(structuredWhitelistArray);

    // process topic
    processTopic(structuredWhitelistArray, structuredBlacklistArray);

    if (whitelistProcessLoading && blacklistProcessLoading && loading) {
      setSaveLoading(false);
    }
  };

  const gobackHandler = () => {
    navigate(-1);
  };

  const processTopic = async (whitelistArray, blacklistArray) => {
    const payload = {
      project_name: projectName,
      version_name: versionName,
      topic_name: topicName,
      whitelist: whitelistArray ? whitelistArray : [],
      blacklist: blacklistArray ? blacklistArray : [],
    };
    setLoading(true);

    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.processTopic,
        payload,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(
          projectActions.addMatchedVerbatimsToTopic({
            projectName: projectName,
            versionName: versionName,
            topicName: topicName,
            matchedRawVerbatims: response.data.matched_raw_verbatims,
            matchedCleanedVerbatims: response.data.matched_cleaned_verbatims,
          })
        );
        toast.success("The topic is processed successfully.");
        setLoading(false);
        setSaveLoading(false);
      }
    } catch (err) {
      toast.error("An error occured while processing topic");
      setLoading(false);
      setSaveLoading(false);
    }
  };

  // Otherwise, it's about a topic which does not exist.
  if (reduxTopicObj === undefined) {
    toast.error("The topic does not exist");
    return (
      <Navigate to={`/project/${projectName}/${versionName}`} replace={true} />
    );
  }

  // If the topic obj does not exist in our state. We go back to project page.
  // If the topic obj is null, it's because the project does not exist.
  if (reduxTopicObj === null) {
    toast.error("The project does not exist");
    return <Navigate to="/" replace={true} />;
  }

  if (reduxTopicObj) {
    return (
      <div className={styles["topic-view-container"]}>
        <div className={styles["action-buttons-wrapper"]}>
          <div className={styles["action-buttons-left-side"]}>
            <GoBackButton onClick={gobackHandler}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </GoBackButton>
          </div>
          <div className={styles["action-buttons-right-side"]}>
            {saveLoading ? (
              <LoadingButton />
            ) : (
              <Button onClick={saveTopicHandler} style={{ fontSize: "20px" }}>
                <FontAwesomeIcon icon={faFloppyDisk} />
              </Button>
            )}
          </div>
        </div>
        <WhitelistBrowser
          contentBoxTitle="Keyword browser"
          tokenType="whitelist"
          topicName={topicName}
          topicObj={reduxTopicObj}
          setAssistantRequested={setAssistantRequested}
        />
        <BlacklistBrowser
          contentBoxTitle="Blacklist browser"
          tokenType="blacklist"
          topicName={topicName}
          topicObj={reduxTopicObj}
          setAssistantRequested={setAssistantRequested}
        />
        <div className={styles["multi-ngram-container"]}>
          {/* 
          Dear developer,
          Here the args 'topicStats' is important.
          If the topic is not processed yet, we use first 'project stats'.
          Otherwise, we will use 'version stats'.
        */}
          <NgramList
            title="Top 1-gram"
            loading={loading}
            topicObj={reduxTopicObj}
            ngramType="top_1grams"
            topicStats={
              versionData.topics?.[topicName]?.stats.top_1grams.length > 0
                ? versionData.topics?.[topicName]?.stats
                : projectStats
            }
          />
          <NgramList
            title="Top 2-gram"
            loading={loading}
            topicObj={reduxTopicObj}
            ngramType="top_2grams"
            topicStats={
              versionData.topics?.[topicName]?.stats.top_2grams.length > 0
                ? versionData.topics?.[topicName]?.stats
                : projectStats
            }
          />
        </div>
        <VerbatimBrowser
          contentBoxTitle="Verbatim examples"
          rawVerbatimsArray={reduxTopicObj?.matchedRawVerbatims}
          cleanedVerbatimsArray={reduxTopicObj?.matchedCleanedVerbatims}
          whitelistedTokens={reduxTopicObj?.attributedTokens}
          loading={loading}
        />
        <AssistantChatGPT
          contentBoxTitle="Assistant"
          topicObj={reduxTopicObj}
          assistantRequested={assistantRequested}
        ></AssistantChatGPT>
      </div>
    );
  }
}

export default TopicView;
