import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function LoadingSpinner(props) {
  const iconCustomStyle = props.iconCustomStyle
    ? props.iconCustomStyle
    : { fontSize: "35px", color: "rgb(213 213 213)" };
  return (
    <>
      <FontAwesomeIcon
        icon={faCircleNotch}
        className="fa-spin"
        style={iconCustomStyle}
      />
    </>
  );
}

export default LoadingSpinner;
