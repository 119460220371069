import React, { useRef } from "react";
import styles from "./Modal.module.css";

export default function Modal(props) {
  const modalWrapperRef = useRef(0);
  const closeModalHandler = (e) => {
    if (e.target === modalWrapperRef.current) {
      props.setModalEnabled(false);
    }
  };
  return (
    <div
      ref={modalWrapperRef}
      className={styles.wrapper}
      onClick={closeModalHandler}
    >
      <div className={styles.content}>{props.children}</div>
    </div>
  );
}
