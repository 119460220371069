import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../components/Button/Button";
import { useProject } from "../../hooks/useProject";
import { projectActions } from "../../store/project-slice";
import styles from "./AssistantChatGPT.module.css";
import RegexBuilder from "./RegexBuilder";
import AssistantResultsTable from "./Tables/AssistantResultsTable";

function AssistantChatGPT(props) {
  const { projectName, versionName, topicName } = useProject();
  const [windowsSize, setWindowsSize] = useState(null);
  const [currentTableName, setCurrentTableName] = useState("synonyms");
  const dropdownMenuRef = useRef("");
  const dispatch = useDispatch();

  const dropdownMenuHandler = () => {
    const currentDisplayMode = dropdownMenuRef.current.style.display;
    if (currentDisplayMode === "flex") {
      dropdownMenuRef.current.style.display = null;
      return;
    }
    dropdownMenuRef.current.style.display = "flex";
    dropdownMenuRef.current.style.alignItems = "flex-start";
  };

  const addKeywordToTopic = (newToken, matchTypeToAdd, tokenType) => {
    // So there are two options. The first one is to add keyword into blacklisted keywords
    // The second one is about adding keyword into whitelisted keywords.
    // The redux store filter that on 'tokenType' key.

    dispatch(
      projectActions.addKeywordToTopic({
        topicName: topicName,
        projectName: projectName,
        versionName: versionName,
        tokenType: tokenType,
        newTokenObj: {
          item: newToken,
          matchType: matchTypeToAdd,
          count: null,
          selected: false,
        },
      })
    );
  };

  const handleResize = () => {
    setWindowsSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span className={styles["title-text"]}>
          <img
            src={process.env.PUBLIC_URL + "/img/gpt-logo.png"}
            style={{ width: "50px", height: "30px" }}
            alt="GPT logo"
          ></img>
          {props.contentBoxTitle}
        </span>
        {windowsSize?.width <= 570 ? (
          <div
            className={styles["dropdown-menu"]}
            onClick={dropdownMenuHandler}
          >
            <div className={styles["dropdown-content"]} ref={dropdownMenuRef}>
              <div
                className={styles["dropdown-item"]}
                onClick={() => setCurrentTableName("synonyms")}
              >
                Synonyms
              </div>
              <div
                className={styles["dropdown-item"]}
                onClick={() => setCurrentTableName("plurals")}
              >
                Plurals
              </div>
              <div
                className={styles["dropdown-item"]}
                onClick={() => setCurrentTableName("mispellings")}
              >
                Mispellings
              </div>
              <div
                className={styles["dropdown-item"]}
                onClick={() => setCurrentTableName("regexBuilder")}
              >
                Regex builder
              </div>
            </div>
            <button className={styles["ellipsis-button"]}>
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </button>
          </div>
        ) : (
          <div className={styles["table-selector-wrapper"]}>
            <Button
              className={`${styles["table-select-button"]} ${
                currentTableName === "synonyms" ? styles.selected : ""
              }`}
              onClick={() => setCurrentTableName("synonyms")}
            >
              Synonyms
            </Button>
            <Button
              className={`${styles["table-select-button"]} ${
                currentTableName === "plurals" ? styles.selected : ""
              }`}
              onClick={() => setCurrentTableName("plurals")}
            >
              Plurals
            </Button>
            <Button
              className={`${styles["table-select-button"]} ${
                currentTableName === "mispellings" ? styles.selected : ""
              }`}
              onClick={() => setCurrentTableName("mispellings")}
            >
              Mispellings
            </Button>
            <Button
              className={`${styles["table-select-button"]} ${
                currentTableName === "regexBuilder" ? styles.selected : ""
              }`}
              onClick={() => setCurrentTableName("regexBuilder")}
            >
              Regex Builder
            </Button>
          </div>
        )}
      </div>
      <div className={styles.content}>
        {currentTableName && (
          <>
            {(() => {
              switch (currentTableName) {
                case "synonyms":
                  return (
                    <AssistantResultsTable
                      assistantType={currentTableName}
                      similaritiesRequested={props.assistantRequested}
                      topicObj={props.topicObj}
                      addKeywordToTopic={addKeywordToTopic}
                    />
                  );
                case "plurals":
                  return (
                    <AssistantResultsTable
                      assistantType={currentTableName}
                      topicObj={props.topicObj}
                      addKeywordToTopic={addKeywordToTopic}
                    />
                  );
                case "mispellings":
                  return (
                    <AssistantResultsTable
                      assistantType={currentTableName}
                      topicObj={props.topicObj}
                      addKeywordToTopic={addKeywordToTopic}
                    />
                  );
                case "regexBuilder":
                  return <RegexBuilder />;
                default:
                  return null;
              }
            })()}
          </>
        )}
      </div>
    </div>
  );
}

export default AssistantChatGPT;
