const BACKEND_MAIN_URL = process.env.REACT_APP_BACKEND_MAIN_URL;
const BACKEND_URL_CONFIG = {
  createProject: `${BACKEND_MAIN_URL}/create-project-service-master`,
  createTopic: `${BACKEND_MAIN_URL}/create-topic-service-master`,
  createVersion: `${BACKEND_MAIN_URL}/create-version-service-master`,
  deleteProject: `${BACKEND_MAIN_URL}/delete-project-service-master`,
  deleteTopic: `${BACKEND_MAIN_URL}/delete-topic-service-master`,
  deleteVersion: `${BACKEND_MAIN_URL}/delete-version-service-master`,
  duplicateVersion: `${BACKEND_MAIN_URL}/duplicate-version-service-master`,
  processAssistant: `${BACKEND_MAIN_URL}/process-assistant-service-master`,
  processProject: `${BACKEND_MAIN_URL}/process-project-service-master`,
  processSimilarities: `${BACKEND_MAIN_URL}/process-similarities-service-master`,
  processTopicBlacklist: `${BACKEND_MAIN_URL}/process-topic-blacklist-service-master`,
  processTopic: `${BACKEND_MAIN_URL}/process-topic-service-master`,
  processTopicWhitelist: `${BACKEND_MAIN_URL}/process-topic-whitelist-service-master`,
  processVersion: `${BACKEND_MAIN_URL}/process-version-service-master`,
  uploadProjectFile: `${BACKEND_MAIN_URL}/upload-project-file-service-master`,
  downloadVersionFile: `${BACKEND_MAIN_URL}/download-version-file-service-master`,
  regexBuilder: `${BACKEND_MAIN_URL}/build-regex-service-master`,
  exportTopics: `${BACKEND_MAIN_URL}/download-topics-service-master`,
  importTopics: `${BACKEND_MAIN_URL}/import-topics-service-master`,
};

export { BACKEND_URL_CONFIG };
