import { faArrowLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";
import GoBackButton from "../../components/Button/GoBackButton";
import LoadingButton from "../../components/Button/LoadingButton";
import RefreshButton from "../../components/Button/RefreshButton";
import Modal from "../../components/Modal/Modal";
import { BACKEND_URL_CONFIG } from "../../config";
import CorpusStats from "../../containers/CorpusStats/CorpusStats";
import ExportTopics from "../../containers/ExportTopics";
import ImportTopics from "../../containers/ImportTopics";
import NgramOverview from "../../containers/NgramStats/NgramOverview";
import TopicBrowser from "../../containers/TopicBrowser/TopicBrowser";
import TopicOverlapTable from "../../containers/TopicOverlapTable/TopicOverlapTable";
import VerbatimBrowser from "../../containers/VerbatimBrowser/VerbatimBrowser";
import { useAuth } from "../../hooks/useAuth";
import { useProject } from "../../hooks/useProject";
import { projectActions } from "../../store/project-slice";
import styles from "./ProjectView.module.css";

function ProjectView() {
  const { projectName, versionName, projectStats, versionData } = useProject();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [topicsData, setTopicsData] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [modalEnabled, setModalEnabled] = useState(false);
  const [modalType, setModalType] = useState("");

  const unclassifiedRawVerbatims = useSelector(
    (state) =>
      state.project[projectName]?.[versionName]?.unclassifiedRawVerbatims
  );
  const unclassifiedCleanedVerbatims = useSelector(
    (state) =>
      state.project[projectName]?.[versionName]?.unclassifiedCleanedVerbatims
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Extract topics info
    const allTopicsArray = [];
    for (const [topicName, topicInfo] of Object.entries(versionData.topics)) {
      dispatch(
        projectActions.addTopic({
          projectName: projectName,
          versionName: versionName,
          topicName: topicName,
        })
      );
      // Add all whitelisted tokens if exists
      topicInfo.whitelist.forEach((tokenObj) => {
        dispatch(
          projectActions.addKeywordToTopic({
            topicName: topicName,
            projectName: projectName,
            versionName: versionName,
            tokenType: "whitelist",
            newTokenObj: {
              item: tokenObj.text,
              matchType: tokenObj.match_type,
              count: tokenObj.count,
              selected: false,
            },
          })
        );
      });

      // Add all blacklisted tokens if exists
      topicInfo.blacklist.forEach((tokenObj) => {
        dispatch(
          projectActions.addKeywordToTopic({
            topicName: topicName,
            projectName: projectName,
            versionName: versionName,
            tokenType: "blacklist",
            newTokenObj: {
              item: tokenObj.text,
              matchType: tokenObj.match_type,
              count: tokenObj.count,
              selected: false,
            },
          })
        );
      });
      allTopicsArray.push({ [topicName]: topicInfo });
    }
    const orderedTopicsArray = allTopicsArray.sort((a, b) => {
      const timestampA = Object.values(a)[0].last_edit;
      const timestampB = Object.values(b)[0].last_edit;

      const dateA = new Date(timestampA).getTime();
      const dateB = new Date(timestampB).getTime();
      return dateB > dateA ? 1 : -1;
    });
    setTopicsData(orderedTopicsArray);
    setLoading(false);
  }, [versionData]);

  useEffect(() => {
    // Trigger automatic in order to have unclassified verbatims
    triggerProcessVersion();
  }, []);

  const gobackHandler = () => {
    navigate(`/versions/${projectName}`);
  };

  const downloadVerbatimHandler = async () => {
    const payload = {
      project_name: projectName,
      version_name: versionName,
    };
    setDownloading(true);
    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.downloadVersionFile,
        payload,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
          responseType: "blob",
        }
      );
      if (response.status === 200) {
        const a = document.createElement("a");
        document.body.appendChild(a);
        const blobUrl = window.URL.createObjectURL(response.data);
        a.href = blobUrl;
        a.download = "dataset_topic.xlsx";
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      }
    } catch (err) {
      toast.error("An error occured while downloading the dataset");
    } finally {
      setDownloading(false);
    }
  };

  const triggerProcessVersion = async () => {
    // Trigger process version is used to update unclassified verbatims.
    // The function can be triggered only with the refresh button on page.

    const payload = {
      project_name: projectName,
      version_name: versionName,
    };

    setFetchingData(true);
    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.processVersion,
        payload,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        // save unclassifed verbatims list
        dispatch(
          projectActions.addUnclassifiedVerbatims({
            projectName: projectName,
            versionName: versionName,
            unclassifiedRawVerbatims: response.data.unclassified_raw_verbatims,
            unclassifiedCleanedVerbatims:
              response.data.unclassified_cleaned_verbatims,
          })
        );
        toast.success("The project data has been successfully updated.");
      }
    } catch (err) {
      toast.error("An error occured while processing version.");
    } finally {
      setFetchingData(false);
    }
  };

  return (
    <>
      {modalEnabled && (
        <Modal setModalEnabled={setModalEnabled}>
          {modalType === "export" && (
            <ExportTopics
              topicsData={topicsData}
              setModalEnabled={setModalEnabled}
            />
          )}
          {modalType === "import" && (
            <ImportTopics setModalEnabled={setModalEnabled} />
          )}
        </Modal>
      )}
      <div className={styles["project-view-container"]}>
        {!loading && (
          <>
            <div className={styles["action-buttons-wrapper"]}>
              <div className={styles["action-buttons-left-side"]}>
                <GoBackButton onClick={gobackHandler}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </GoBackButton>
              </div>
              <div className={styles["action-buttons-right-side"]}>
                {fetchingData ? (
                  <LoadingButton />
                ) : (
                  <RefreshButton onClick={triggerProcessVersion} />
                )}
                {downloading ? (
                  <LoadingButton />
                ) : (
                  <Button
                    onClick={downloadVerbatimHandler}
                    disabled={downloading === true}
                    style={{ fontSize: "20px" }}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                )}
              </div>
            </div>
            <TopicBrowser
              contentBoxTitle="Topic browsers"
              topicsData={topicsData}
              projectName={projectName}
              versionName={versionName}
              setModalEnabled={setModalEnabled}
              setModalType={setModalType}
              modalEnabled={modalEnabled}
            />
            <TopicOverlapTable contentBoxTitle="Overlap topics table" />
            <CorpusStats
              contentBoxTitle="Corpus Stats"
              corpusStatsInfo={versionData.stats}
              projectStatsInfo={projectStats}
              totalTopicsNb={topicsData?.length}
            />
            <NgramOverview
              title="Top 1-gram overview"
              totalVerbatimCount={projectStats.count}
              ngramArray={projectStats.top_1grams}
            />
            <NgramOverview
              title="Top 2-gram overview"
              totalVerbatimCount={projectStats.count}
              ngramArray={projectStats.top_2grams}
            />
            <VerbatimBrowser
              contentBoxTitle="Unclassified verbatim examples"
              verbatimType="unclassified"
              rawVerbatimsArray={unclassifiedRawVerbatims}
              cleanedVerbatimsArray={unclassifiedCleanedVerbatims}
              loading={false}
            />
          </>
        )}
      </div>
    </>
  );
}

export default ProjectView;
