import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Stepper from "../../components/Stepper/Stepper";
import CreateProject from "../../containers/CreateProject";
import FileUploader from "../../containers/FileUploader";
import { db } from "../../firebase/firebase";
import styles from "./NewProject.module.css";

function NewProject() {
  const steps = ["Create project", "Upload data"];
  const [currentStep, setCurrentStep] = useState(1);
  const [projectName, setProjectName] = useState("");
  const urlParams = useParams();

  const getSectionComponent = () => {
    switch (currentStep) {
      case 1:
        return (
          <CreateProject
            setProjectName={setProjectName}
            setCurrentStep={setCurrentStep}
          />
        );
      case 2:
        return (
          <FileUploader
            projectName={projectName}
            setCurrentStep={setCurrentStep}
          />
        );
      default:
        return null;
    }
  };

  const checkIfProjectExists = async (projectNameToMatch) => {
    const projectsRef = collection(db, "projects");
    const querySnapshot = query(
      projectsRef,
      where("project_name", "==", projectNameToMatch)
    );
    const docSnapshot = await getDocs(querySnapshot);
    const ifExists = (await docSnapshot.empty) === false;
    return ifExists;
  };

  const checkIfProjectProcessed = async (projectNameToMatch) => {
    const projectsRef = collection(db, "projects");
    const querySnapshot = query(
      projectsRef,
      where("project_name", "==", projectNameToMatch),
      where("in_progress", "==", false)
    );
    const docSnapshot = await getDocs(querySnapshot);
    const ifProcessed = (await docSnapshot.empty) === false;
    return ifProcessed;
  };

  useEffect(() => {
    const checkInputUser = async () => {
      if (urlParams.projectName) {
        const ifExists = await checkIfProjectExists(urlParams.projectName);
        if (!ifExists) {
          toast.error("Project not found !");
          navigate("/404");
        } else if (
          (await checkIfProjectProcessed(urlParams.projectName)) === false
        ) {
          setProjectName(urlParams.projectName);
          setCurrentStep(2);
        } else if (await checkIfProjectProcessed(urlParams.projectName)) {
          navigate(`/versions/${urlParams.projectName}`);
        }
      }
    };
    checkInputUser();
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <div className={styles["create-project-container"]}>
        <div className={styles["stepper-container"]}>
          <Stepper currentStep={currentStep} steps={steps} />
        </div>
        <div className={styles.container}>
          <div className={styles["section-container"]}>
            {getSectionComponent()}
          </div>
        </div>
      </div>
    </>
  );
}

export default NewProject;
