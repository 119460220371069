import React from "react";
import PercentageCircle from "../../components/PercentageCircle/PercentageCircle";
import styles from "./CorpusStats.module.css";

function CorpusStats(props) {
  const totalTopicsNb = props.totalTopicsNb ? props.totalTopicsNb : 0;

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span className={styles["title-text"]}>{props.contentBoxTitle}</span>
      </div>
      <div className={styles.content}>
        <div className={styles["topic-stats-wrapper"]}>
          <div>
            <span>Number of topics : </span>
            <span>
              <b>{totalTopicsNb}</b>
            </span>
          </div>
          <div>
            <span>Number of verbatims : </span>
            <span>
              <b>{props.projectStatsInfo.count}</b>
            </span>
          </div>
        </div>
        <div className={styles["percentage-content-wrapper"]}>
          <div className={styles["classified-verbatims-wrapper"]}>
            <span className={styles["percentage-explication-title"]}>
              <b>Classified</b> verbatims percentage :{" "}
            </span>
            <PercentageCircle
              percentage={Math.round(
                props.corpusStatsInfo.classified_verbatims_percentage * 100
              )}
              textSize="10px"
              circleSize="50px"
            />
          </div>
          <div className={styles["multiclassified-verbatims-wrapper"]}>
            <span className={styles["percentage-explication-title"]}>
              <b>Multi-classified</b> verbatims percentage :{" "}
            </span>
            <PercentageCircle
              percentage={Math.round(
                props.corpusStatsInfo.multiclassified_verbatims_percentage * 100
              )}
              textSize="10px"
              circleSize="50px"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CorpusStats;
