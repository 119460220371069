import {
  faCheckCircle,
  faDownload,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { toast } from "react-toastify";
import styles from "./JsonUploader.module.css";

function JsonUploader({
  isFailed,
  setIsFailed,
  uploadedFile,
  setUploadedFile,
  setFileContent,
}) {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = React.useRef(null);

  const readAndSaveFile = (fileObj) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      setFileContent(event.target.result);
    };
    reader.readAsText(fileObj);
    setUploadedFile(fileObj);
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    // If there is a new upload, we reset fail status
    setIsFailed(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (e.dataTransfer.files[0].type !== "application/json") {
        toast.error("Only JSON files are allowed to import.");
        return;
      }
      readAndSaveFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    // If there is a new upload, we reset fail status
    setIsFailed(false);
    if (e.target.files && e.target.files[0]) {
      readAndSaveFile(e.target.files[0]);
    }
  };

  return (
    <>
      <div
        className={styles["upload-input-container"]}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <div className={styles["uplaod-information-wrapper"]}>
          <>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{ color: "#2470d1", fontSize: "20px" }}
            />
            <span>
              <b>Attention: </b> You should only import JSON files that you've
              downloaded.
            </span>
          </>
        </div>
        <form
          className={styles["file-upload-form"]}
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            ref={inputRef}
            id={styles["input-file"]}
            type="file"
            accept=".json"
            name="fileUpload"
            onChange={handleChange}
          />
          <label
            htmlFor={styles["input-file"]}
            id="file-drag"
            className={dragActive ? "drag-active" : ""}
          >
            {!uploadedFile ? (
              <div className={styles["upload-information-wrapper"]}>
                <FontAwesomeIcon
                  icon={faDownload}
                  className={styles["upload-file-icon"]}
                />
                <span style={{ textAlign: "center" }}>
                  Choose a file or drag it here
                </span>
              </div>
            ) : (
              <div className={styles["upload-information-wrapper"]}>
                {isFailed ? (
                  <>
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      style={{ color: "#d12424", fontSize: "320%" }}
                    />
                    <div className={styles["upload-message"]}>
                      <div style={{ textAlign: "center" }}>
                        <span style={{ fontWeight: "bold" }}>
                          {uploadedFile.name}
                        </span>{" "}
                        could not be processed. Please check your file !
                      </div>
                      <div style={{ textAlign: "center" }}>
                        To change, choose a new file or drag it again here.
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "green", fontSize: "50px" }}
                    />
                    <div className={styles["upload-message"]}>
                      <div style={{ textAlign: "center" }}>
                        <span style={{ fontWeight: "bold" }}>
                          {uploadedFile.name}
                        </span>{" "}
                        is received.
                      </div>
                      <div style={{ textAlign: "center" }}>
                        To change, choose a new file or drag it again here.
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </label>
        </form>
      </div>
    </>
  );
}

export default JsonUploader;
