import React from "react";
import MainLayout from "../../components/Layout/MainLayout";
import ProjectsOverview from "../../containers/ProjectsOverview";

function Homepage() {
  return (
    <>
      <MainLayout>
        <ProjectsOverview />
      </MainLayout>
    </>
  );
}

export default Homepage;
