import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import EditButton from "../../components/Button/EditButton";
import SaveButton from "../../components/Button/SaveButton";
import Tag from "../../components/Tag/Tag";
import { useProject } from "../../hooks/useProject";
import { projectActions } from "../../store/project-slice";
import styles from "./TokenItem.module.css";

function TokenItem(props) {
  const keywordCheckboxRef = useRef(0);
  const topicNameInputRef = useRef(0);
  const tokenTypeInputRef = useRef(0);
  const tokenAreaRef = useRef(0);
  const [editEnabled, setEditEnabled] = useState(false);
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const { projectName, versionName, topicName } = useProject();
  const dispatch = useDispatch();

  const selectRowHandler = (e) => {
    if (
      e.target !== keywordCheckboxRef.current &&
      e.target === tokenAreaRef.current
    ) {
      keywordCheckboxRef.current.checked = !keywordCheckboxRef.current.checked;
      dispatch(
        projectActions.selectOneToken({
          topicName: topicName,
          projectName: projectName,
          versionName: versionName,
          tokenType: props.tokenType,
          item: props.tokenObj.item,
          isSelected: keywordCheckboxRef.current.checked,
        })
      );
    }
  };

  const handleCheckboxChange = (e) => {
    dispatch(
      projectActions.selectOneToken({
        topicName: topicName,
        projectName: projectName,
        versionName: versionName,
        tokenType: props.tokenType,
        item: props.tokenObj.item,
        isSelected: keywordCheckboxRef.current.checked,
      })
    );
  };

  const updateTokenObj = (oldTokenToUpdate) => {
    const inputMatchType = tokenTypeInputRef.current.value;
    const inputToken = topicNameInputRef.current.value;
    const normalizedToken = inputToken.replace(/\s{1,}/g, " ").toLowerCase();
    if (normalizedToken === "" || normalizedToken === " ") {
      toast.error("Your token can not be empty.");
      return;
    }
    dispatch(
      projectActions.updateKeyword({
        topicName: topicName,
        projectName: projectName,
        versionName: versionName,
        tokenType: props.tokenType,
        oldToken: oldTokenToUpdate,
        newTokenObj: {
          item: normalizedToken,
          matchType: inputMatchType ? inputMatchType : null,
          count: null,
          selected: false,
        },
      })
    );
    // we disable textbox and select dropdown to edit the token
    setEditEnabled(false);
    setSaveButtonEnabled(false);
  };

  // Update token object
  const editButtonHandler = () => {
    setEditEnabled(!editEnabled);
    setSaveButtonEnabled(!saveButtonEnabled);
  };

  const topicNameChangeHandler = (event, oldTokenToUpdate) => {
    if (event.key === "Enter") {
      updateTokenObj(oldTokenToUpdate);
    }
  };

  const updateTopicObjSaveHandler = (oldTokenToUpdate) => {
    updateTokenObj(oldTokenToUpdate);
  };

  return (
    <div className={styles["keyword-container"]} onClick={selectRowHandler}>
      <div className={styles["keyword-checkbox-wrapper"]}>
        <input
          ref={keywordCheckboxRef}
          className={styles["keyword-checkbox"]}
          type="checkbox"
          checked={props.tokenObj.selected}
          onChange={handleCheckboxChange}
        ></input>
      </div>

      <div className={styles["keyword-item"]} ref={tokenAreaRef}>
        {editEnabled ? (
          <input
            type="text"
            className={styles["token-name-edit-textfield"]}
            defaultValue={props.tokenObj.item}
            ref={topicNameInputRef}
            onKeyDown={(e) => topicNameChangeHandler(e, props.tokenObj.item)}
          />
        ) : (
          props.tokenObj.item
        )}
      </div>
      {props.tokenType === "blacklist" && (
        <Tag bgColor="#000000c4">BLACKLISTED</Tag>
      )}
      {editEnabled ? (
        <select
          defaultValue={
            props.tokenObj.matchType
              ? props.tokenObj.matchType.toUpperCase()
              : "STRING"
          }
          ref={tokenTypeInputRef}
          className={styles["match-type-selector"]}
          name="matchType"
        >
          <option value="STRING">STR</option>
          <option value="WORD">WORD</option>
          <option value="REGEX">REGEX</option>
        </select>
      ) : (
        <Tag
          color="black"
          bgColor={
            props.tokenObj.matchType?.toUpperCase() === "STRING"
              ? "#f0dca5"
              : props.tokenObj.matchType?.toUpperCase() === "REGEX"
              ? "#a5e6f0"
              : "#e3cbe5"
          }
        >
          {props.tokenObj.matchType?.toUpperCase()}
        </Tag>
      )}
      {keywordCheckboxRef.current.checked &&
        (saveButtonEnabled ? (
          <SaveButton
            onClick={() => updateTopicObjSaveHandler(props.tokenObj.item)}
          />
        ) : (
          <EditButton onClick={editButtonHandler} />
        ))}
    </div>
  );
}

export default TokenItem;
