import styles from "./ToggleSwitch.module.css";

function ToggleSwitch(props) {
  return (
    <>
      <label className={styles.switch}>
        <input
          ref={props.inputRef}
          onChange={props.onChange}
          type="checkbox"
          disabled={props.disabled}
          checked={props.checked}
        />
        <span></span>
      </label>
    </>
  );
}

export default ToggleSwitch;
