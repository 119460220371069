import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { auth, db } from "../firebase/firebase";

export const AuthContext = createContext();

const AuthProvider = (props) => {
  const [currentUser, setCurrentUser] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const login = async () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider)
      .then((result) => {
        toast(`👋 Welcome ${result.user.displayName}`);
        navigate("/");
      })
      .catch((err) => {});
  };

  const logout = async () => {
    return await auth.signOut();
  };

  const checkIfAdmin = async (userEmailToCheck) => {
    const projectsRef = collection(db, "users");
    const querySnapshot = query(
      projectsRef,
      where("email", "==", userEmailToCheck)
    );
    const docSnapshot = await getDocs(querySnapshot);
    const doc = docSnapshot.docs[0];
    if (doc) {
      const userInfo = doc.data();
      setIsAdmin(userInfo.email);
    }
  };

  useEffect(() => {
    const unsubcribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        checkIfAdmin(user?.email);
      }
      setLoading(false);
    });
    return unsubcribe;
  }, []);

  const value = { currentUser, isAdmin, login, logout };

  return (
    <AuthContext.Provider value={value}>
      {!loading && props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
