import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import { useProject } from "../hooks/useProject";

const PrivateVersion = () => {
  const { projectName, versionName, isOwner } = useProject();
  if (!isOwner) {
    toast.error(`You are not the owner of the version '${versionName}'.`);
    return <Navigate to={`/versions/${projectName}`} replace={true} />;
  }
  return (
    <>
      <Outlet />
    </>
  );
};

export default PrivateVersion;
