import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";
import LoadingButton from "../../components/Button/LoadingButton";
import { BACKEND_URL_CONFIG } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import { useProject } from "../../hooks/useProject";
import JsonUploader from "../JsonUploader";
import styles from "./ImportTopics.module.css";

export default function ImportTopics({ setModalEnabled }) {
  const { currentUser } = useAuth();
  const { projectName, versionName } = useProject();
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isFailed, setIsFailed] = useState(false);
  const [fileContent, setFileContent] = useState(null);

  const uploadFileHandler = async () => {
    let convertedJSONFileContent;
    try {
      convertedJSONFileContent = JSON.parse(fileContent);
    } catch (err) {
      setIsFailed(true);
      toast.error("The file is not a valid JSON file. Please check your file.");
      return;
    }

    const payload = {
      project_name: projectName,
      version_name: versionName,
      topic_informations_dict: convertedJSONFileContent,
    };

    setLoading(true);
    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.importTopics,
        payload,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        const numberImportedTopics = Object.keys(
          convertedJSONFileContent
        ).length;
        toast.success(`${numberImportedTopics} topic(s) imported successfully`);
        setModalEnabled(false);
      }
    } catch (err) {
      setIsFailed(true);
      toast.error("An error occured while importing json file.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={styles.container}>
      <div
        className={styles["close-button-wrapper"]}
        onClick={() => setModalEnabled(false)}
      >
        <FontAwesomeIcon icon={faXmark} />
      </div>
      <div className={styles.title}>Import topic(s)</div>
      <div className={styles.content}>
        <JsonUploader
          isFailed={isFailed}
          setIsFailed={setIsFailed}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
          setFileContent={setFileContent}
        />
      </div>
      <div className={styles.footer}>
        {loading ? (
          <LoadingButton>Importing...</LoadingButton>
        ) : (
          <Button
            disabled={uploadedFile?.name === undefined}
            onClick={uploadFileHandler}
          >
            Upload file
          </Button>
        )}
      </div>
    </div>
  );
}
