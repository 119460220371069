import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./AddButton.module.css";

function AddButton(props) {
  // The className can be custom, so we check first if there is any props about that
  const className = props.className
    ? props.className
    : styles["add-icon-container"];

  return (
    <div
      className={className}
      onClick={props.onClick}
      title={props.title}
      style={props.customStyle}
    >
      <FontAwesomeIcon icon={faPlusCircle} />
    </div>
  );
}

export default AddButton;
