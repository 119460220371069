import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AddButton from "../../components/Button/AddButton";
import Button from "../../components/Button/Button";
import DeleteButton from "../../components/Button/DeleteButton";
import Pagination from "../../components/Pagination/Pagination";
import { BACKEND_URL_CONFIG } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import { useProject } from "../../hooks/useProject";
import { projectActions } from "../../store/project-slice";
import TokenItem from "./TokenItem";
import styles from "./WhitelistBrowser.module.css";

function WhitelistBrowser(props) {
  const { currentUser } = useAuth();
  const { topicName, projectName, versionName, setAssistantRequested } =
    useProject();
  const dispatch = useDispatch();
  const urlParams = useParams();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTokens, setSelectedTokens] = useState([]);
  const [windowsSize, setWindowsSize] = useState(null);
  const matchTypeRef = useRef(0);
  const inputUserRef = useRef(0);
  const allSelectedInputRef = useRef(0);
  const dropdownMenuRef = useRef("");

  const totalItemNumber = props.topicObj.attributedTokens.length;
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTokensArray = [...props.topicObj.attributedTokens]
    .reverse()
    .slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const dropdownMenuHandler = () => {
    const currentDisplayMode = dropdownMenuRef.current.style.display;
    if (currentDisplayMode === "flex") {
      dropdownMenuRef.current.style.display = null;
      return;
    }
    dropdownMenuRef.current.style.display = "flex";
    dropdownMenuRef.current.style.alignItems = "flex-start";
  };

  const checkboxChangeHandler = (e) => {
    dispatch(
      projectActions.selectAllTokens({
        topicName: props.topicObj.topicName,
        projectName: projectName,
        versionName: versionName,
        tokenType: props.tokenType,
        isSelected: e.target.checked,
      })
    );
  };

  const addKeywordToTopic = (token, matchType) => {
    // Replace all space with one space
    const normalizedToken = token.replace(/\s{1,}/g, " ").toLowerCase();
    if (normalizedToken === "" || normalizedToken === " ") {
      return;
    }

    dispatch(
      projectActions.addKeywordToTopic({
        topicName: topicName,
        projectName: projectName,
        versionName: versionName,
        tokenType: props.tokenType,
        newTokenObj: {
          item: normalizedToken,
          matchType: matchType ? matchType : null,
          count: null,
          selected: false,
        },
      })
    );

    // Clean input
    inputUserRef.current.value = "";
  };

  const deleteKeywordFromTopic = () => {
    selectedTokens.forEach((eachToken) => {
      dispatch(
        projectActions.deleteKeywordFromTopic({
          projectName: projectName,
          versionName: versionName,
          topicName: topicName,
          tokenToDel: eachToken.item,
          tokenType: props.tokenType,
        })
      );
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // get if the token is STR or REGEX
      let matchTypeToAdd = matchTypeRef.current.value;
      let tokenToAdd = inputUserRef.current.value;

      // Shortcuts
      if (tokenToAdd.startsWith('"') && tokenToAdd.endsWith('"')) {
        matchTypeToAdd = "WORD";
        tokenToAdd = tokenToAdd.replaceAll('"', "");
      } else if (tokenToAdd.startsWith('r"') && tokenToAdd.endsWith('"')) {
        matchTypeToAdd = "REGEX";
        tokenToAdd = tokenToAdd.replace('r"', "");
        tokenToAdd = tokenToAdd.replace('"', "");
      }

      // Insert token into state
      addKeywordToTopic(tokenToAdd, matchTypeToAdd);

      // Clean input
      inputUserRef.current.value = "";
    }
  };

  const clickSaveHandler = () => {
    // get if the token is STR or REGEX
    const matchTypeToAdd = matchTypeRef.current.value;
    const tokenToAdd = inputUserRef.current.value;

    // Insert token into state
    addKeywordToTopic(tokenToAdd, matchTypeToAdd);
  };

  const assistantProcessHandler = async (assistantType) => {
    toast.info("Your request is successfully saved to process !");
    const payload = {
      project_name: urlParams.projectName,
      version_name: urlParams.versionName,
      topic_name: urlParams.topicName,
      assistant_input: {
        assistant_type: assistantType,
        item_list: selectedTokens.map((token) => token.item),
      },
    };

    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.processAssistant,
        payload,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        if (assistantType === "synonyms") {
          setAssistantRequested(true);
        }
        toast.success(
          `Your request '${assistantType}' was succesfully processed.`
        );
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const handleResize = () => {
    setWindowsSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (
      props.topicObj?.attributedTokens.every((tokenData) => tokenData.selected)
    ) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [props.topicObj?.attributedTokens]);

  useEffect(() => {
    setSelectedTokens(
      props.topicObj?.attributedTokens.filter((tokenData) => tokenData.selected)
    );
  }, [props.topicObj?.attributedTokens]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span className={styles["title-text"]}>
          {props.contentBoxTitle} - {selectedTokens.length} of {totalItemNumber}
        </span>
        {selectedTokens.length > 0 &&
          (windowsSize?.width <= 760 ? (
            <div
              className={styles["dropdown-menu"]}
              onClick={dropdownMenuHandler}
            >
              <div className={styles["dropdown-content"]} ref={dropdownMenuRef}>
                <div
                  className={styles["dropdown-item"]}
                  onClick={() => assistantProcessHandler("synonyms")}
                >
                  Synonyms assistant
                </div>
                <div
                  className={styles["dropdown-item"]}
                  onClick={() => assistantProcessHandler("plurals")}
                >
                  Plurals assistant
                </div>
                <div
                  className={styles["dropdown-item"]}
                  onClick={() => assistantProcessHandler("mispellings")}
                >
                  Mispellings assistant
                </div>
              </div>
              <button className={styles["ellipsis-button"]}>
                <FontAwesomeIcon icon={faEllipsisVertical} />
              </button>
            </div>
          ) : (
            <>
              <Button
                className={styles["assistant-button"]}
                title="Get similar keywords"
                onClick={() => assistantProcessHandler("synonyms")}
              >
                Synonyms assistant
              </Button>
              <Button
                className={styles["assistant-button"]}
                title="Get the selected keywords in plural format."
                onClick={() => assistantProcessHandler("plurals")}
              >
                Plurals assistant
              </Button>
              <Button
                className={styles["assistant-button"]}
                title="Get misspelled keywords for the selected keywords."
                onClick={() => assistantProcessHandler("mispellings")}
              >
                Mispellings assistant
              </Button>
              <DeleteButton
                onClick={deleteKeywordFromTopic}
                className={styles["custom-delete-button"]}
              />
            </>
          ))}
        <input
          ref={allSelectedInputRef}
          className={styles["all-selected-checkbox"]}
          type="checkbox"
          checked={isAllSelected}
          onChange={checkboxChangeHandler}
        />
      </div>
      <div className={styles["input-wrapper"]}>
        <div className={styles["input-row"]}>
          <AddButton onClick={clickSaveHandler} />
          <input
            ref={inputUserRef}
            type="text"
            placeholder="Type your new token..."
            className={styles["match-type-input-text"]}
            onKeyDown={handleKeyDown}
          />
          <select
            defaultValue="STRING"
            ref={matchTypeRef}
            className={styles["match-type-selector"]}
            name="matchType"
          >
            <option value="STRING">STR</option>
            <option value="WORD">WORD</option>
            <option value="REGEX">REGEX</option>
          </select>
        </div>
      </div>
      <div className={styles.content}>
        {currentTokensArray.map((tokenObj, index) => (
          <TokenItem
            topicObj={props.topicObj}
            tokenObj={tokenObj}
            tokenType={props.tokenType}
            topicName={props.topicObj.topicName}
            key={index}
          ></TokenItem>
        ))}
      </div>
      <div className={styles.footer}>
        <Pagination
          currentPage={currentPage}
          totalItemsNumber={totalItemNumber}
          itemsPerPage={itemsPerPage}
          paginate={paginate}
        />
      </div>
    </div>
  );
}

export default WhitelistBrowser;
