import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { collection, onSnapshot } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";
import DeleteButton from "../../components/Button/DeleteButton";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Tag from "../../components/Tag/Tag";
import { BACKEND_URL_CONFIG } from "../../config";
import { db } from "../../firebase/firebase";
import { useAuth } from "../../hooks/useAuth";
import styles from "./ProjectsOverview.module.css";

function ProjectsOverview() {
  const { currentUser, isAdmin } = useAuth();
  const [loading, setLoading] = useState(false);
  const [projectInfosArray, setProjectInfosArray] = useState([]);
  const projectNameRef = useRef([]);
  const navigate = useNavigate();

  const deleteProjectHandler = async (index) => {
    if (!isAdmin) {
      toast.error(
        "You don't have admin permissions to delete a project. Please contact an administrator."
      );
      return;
    }
    setLoading(true);
    const payload = {
      project_name: projectNameRef.current[index].innerHTML,
    };

    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.deleteProject,
        payload,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(
          `The project '${payload.project_name}' is deleted successfully.`
        );
        setLoading(false);
      }
    } catch (err) {
      toast.error("An error occured while deleting a project");
      setLoading(false);
    }
  };

  const createProjectButtonHandler = () => {
    navigate("/createProject");
  };

  const selectProjectHandler = (projectName, status) => {
    if (status !== "PROCESSED") {
      navigate(`/createProject/${projectName}`);
      return;
    }
    navigate(`/versions/${projectName}`);
    return;
  };

  useEffect(() => {
    setLoading(true);
    // Connectin to db
    const projectsRef = collection(db, "projects");
    const unsubscribe = onSnapshot(projectsRef, (snapShot) => {
      const listProjects = snapShot.docs.map((doc) => {
        const dataObj = doc.data();
        const projectName = dataObj.project_name;
        const computeStatus = dataObj.compute_status;
        const creationDateTimestamp = dataObj.creation_date * 1000;
        const creationDate = new Date(creationDateTimestamp);
        const formattedDate = creationDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        return {
          projectName: projectName,
          computeStatus: computeStatus,
          creationDate: formattedDate,
          timestamp: dataObj.creation_date,
        };
      });
      setProjectInfosArray(
        listProjects.sort((a, b) => b.timestamp - a.timestamp)
      );
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles["title-wrapper"]}>
          <span className={styles.title}>Projects</span>
          <div className={styles["action-buttons-wrapper"]}>
            <Button onClick={createProjectButtonHandler}>New project</Button>
          </div>
        </div>
        <div className={styles["project-overview-container"]}>
          {loading ? (
            <LoadingSpinner />
          ) : projectInfosArray?.length === 0 && !loading ? (
            <div className={styles["empty-table-wrapper"]}>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{ color: "#a60909", fontSize: "40px" }}
              />
              <span>There is no projects. Please create new one.</span>
            </div>
          ) : (
            <>
              <div className={styles["table-container"]}>
                <table id={styles["project-table"]}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Project name</th>
                      <th>Created at</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectInfosArray?.map((doc, index) => (
                      <tr key={index} className={styles.row}>
                        <th>
                          {doc.computeStatus !== "PROCESSED" ? (
                            <FontAwesomeIcon
                              title="The project is not processed"
                              icon={faExclamationCircle}
                              style={{ color: "#a60909", fontSize: "16px" }}
                            />
                          ) : (
                            index + 1
                          )}
                        </th>
                        <th
                          ref={(el) => (projectNameRef.current[index] = el)}
                          onClick={() =>
                            selectProjectHandler(
                              doc.projectName,
                              doc.computeStatus
                            )
                          }
                          style={{
                            cursor: "pointer",
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          {doc.projectName}
                        </th>
                        <th>{doc.creationDate}</th>
                        <th>
                          {doc.computeStatus === "PROCESSED" ? (
                            <Tag bgColor="#004800">PROCESSED</Tag>
                          ) : (
                            <Tag bgColor="#710202">NOT PROCESSED</Tag>
                          )}
                        </th>
                        <th>
                          <DeleteButton
                            onClick={() => deleteProjectHandler(index)}
                          />
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ProjectsOverview;
