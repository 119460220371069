import React, { useState } from "react";
import Pagination from "../../components/Pagination/Pagination";
import { useProject } from "../../hooks/useProject";
import styles from "./NgramOverview.module.css";
function NgramOverview(props) {
  const { versionName } = useProject();
  const [currentPage, setCurrentPage] = useState(1);
  const totalNgrams = props.ngramArray?.length;
  const ngramsPerPage = 10;

  const indexOfLastNgram = currentPage * ngramsPerPage;
  const indexOfFirstNgram = indexOfLastNgram - ngramsPerPage;
  const currentNgrams = props.ngramArray
    ?.filter((ngram) => !ngram[`classified__${versionName}`])
    .slice(indexOfFirstNgram, indexOfLastNgram);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span className={styles["box-title"]}>{props.title}</span>
      </div>
      <div className={styles["content-container"]}>
        {currentNgrams?.map((ngramObj, index) => (
          <div className={styles["ngram-row"]} key={index}>
            <div className={styles.token}>{ngramObj.ngram}</div>
            <div className={styles.ngrambar}>
              <div
                style={{
                  width: (ngramObj.count / props.totalVerbatimCount) * 100,
                }}
                className={styles["progress-bar"]}
              ></div>
              <div className={styles["ngram-count"]}>{ngramObj.count}</div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <Pagination
          currentPage={currentPage}
          totalItemsNumber={totalNgrams}
          itemsPerPage={ngramsPerPage}
          paginate={paginate}
        />
      </div>
    </div>
  );
}

export default NgramOverview;
