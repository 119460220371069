import { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginLayout from "./components/Layout/LoginLayout";
import MainLayout from "./components/Layout/MainLayout";
import CreateVersion from "./containers/CreateVersion";
import VersionsOverview from "./containers/VersionsOverview";
import AuthProvider from "./contexts/AuthContext";
import ProjectProvider from "./contexts/ProjectContext";
import NotFound from "./pages/Errors/404";
import Homepage from "./pages/Homepage";
import NewProject from "./pages/NewProject";
import ProjectView from "./pages/ProjectView";
import TopicView from "./pages/TopicView";
import PrivateVersion from "./routes/PrivateProject";
import PrivateRoute from "./routes/PrivateRoute";

function App() {
  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <BrowserRouter basename="/">
        <AuthProvider>
          <Routes>
            <Route path="*" element={<NotFound />}></Route>
            <Route path="/login" element={<LoginLayout />}></Route>
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<Homepage />}></Route>
              <Route element={<ProjectProvider />}>
                <Route element={<PrivateVersion />}>
                  <Route
                    path="/project/:projectName/:versionName"
                    element={
                      <MainLayout>
                        <ProjectView />
                      </MainLayout>
                    }
                  ></Route>
                  <Route path="/project/:projectName/:versionName/topics">
                    <Route
                      path=":topicName"
                      element={
                        <MainLayout>
                          <TopicView />
                        </MainLayout>
                      }
                    ></Route>
                  </Route>
                </Route>
              </Route>
              <Route
                path="/createProject"
                element={
                  <MainLayout>
                    <NewProject />
                  </MainLayout>
                }
              >
                <Route
                  path=":projectName"
                  element={
                    <MainLayout>
                      <NewProject />
                    </MainLayout>
                  }
                ></Route>
              </Route>
              <Route
                path="/versions/:projectName"
                element={
                  <MainLayout>
                    <VersionsOverview />
                  </MainLayout>
                }
              ></Route>
              <Route
                path="/createVersion/:projectName"
                element={
                  <MainLayout>
                    <CreateVersion />
                  </MainLayout>
                }
              ></Route>
            </Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
