import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Pagination from "../../components/Pagination/Pagination";
import ToggleSwitch from "../../components/Switch/ToggleSwitch";
import styles from "./VerbatimBrowser.module.css";

function VerbatimBrowser(props) {
  const [toggleState, setToggleState] = useState("raw");
  const [currentPage, setCurrentPage] = useState(1);
  const [verbatimsArray, setVerbatimsArray] = useState([]);
  const toggleRef = useRef(0);
  const totalItemNumber = verbatimsArray?.length;
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredVerbatimsArray = [
    ...(verbatimsArray ? verbatimsArray : []),
  ].slice(indexOfFirstItem, indexOfLastItem);
  const whitelistedTokensArray = props.whitelistedTokens?.map(
    (token) => token.item
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const verbatimToggleHandler = () => {
    if (toggleRef.current.checked) {
      setToggleState("cleaned");
      return;
    }
    setToggleState("raw");
  };

  useEffect(() => {
    if (toggleState === "raw") {
      setVerbatimsArray(props.rawVerbatimsArray);
      return;
    }
    setVerbatimsArray(props.cleanedVerbatimsArray);
  }, [toggleState, props.rawVerbatimsArray, props.cleanedVerbatimsArray]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span className={styles["title-text"]}>{props.contentBoxTitle}</span>
        <div className={styles["switch-wrapper"]}>
          <span style={{ fontSize: "12px" }}>{toggleState}</span>
          <ToggleSwitch inputRef={toggleRef} onChange={verbatimToggleHandler} />
        </div>
      </div>
      <div className={styles.content}>
        {filteredVerbatimsArray.length === 0 && !props.loading ? (
          <div className={styles["empty-table-wrapper"]}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{ color: "#a60909", fontSize: "40px" }}
            />
            <span>There is no verbatims to display.</span>
          </div>
        ) : props.loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className={styles["table-container"]}>
              <table id={styles["project-table"]}>
                <tbody>
                  {filteredVerbatimsArray?.map((verbatim, index) => (
                    <tr key={index} className={styles.row}>
                      <th>{indexOfLastItem - itemsPerPage + index + 1}</th>
                      {/* 
                      Here we split verbatim by regex with all whitelisted tokens
                      and then we put them bold 
                      */}
                      <th>
                        {verbatim
                          .split(
                            new RegExp(`(${whitelistedTokensArray?.join("|")})`)
                          )
                          .map((part, i) =>
                            whitelistedTokensArray?.includes(
                              part.toLowerCase()
                            ) ? (
                              <strong key={i}>{part}</strong>
                            ) : (
                              part
                            )
                          )}
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.footer}>
              <Pagination
                currentPage={currentPage}
                totalItemsNumber={totalItemNumber}
                itemsPerPage={itemsPerPage}
                paginate={paginate}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default VerbatimBrowser;
