import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AddButton from "../../components/Button/AddButton";
import BlacklistButton from "../../components/Button/BlacklistButton";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Pagination from "../../components/Pagination/Pagination";
import { useProject } from "../../hooks/useProject";
import { projectActions } from "../../store/project-slice";
import styles from "./NgramList.module.css";

function NgramList(props) {
  const { projectName, versionName, topicName } = useProject();
  const [currentPage, setCurrentPage] = useState(1);
  const [initNgramsArray, setInitNgramsArray] = useState([]);
  const [maxVerbatimCount, setMaxVerbatimCount] = useState(0);
  const totalNgrams = initNgramsArray?.length;
  const ngramsPerPage = 10;

  const indexOfLastNgram = currentPage * ngramsPerPage;
  const indexOfFirstNgram = indexOfLastNgram - ngramsPerPage;
  const currentNgrams = initNgramsArray?.slice(
    indexOfFirstNgram,
    indexOfLastNgram
  );

  const dispatch = useDispatch();
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const addKeywordToTopic = (newNgramObj, matchTypeToAdd, tokenType) => {
    // So there are two options. The first one is to add keyword into blacklisted keywords
    // The second one is about adding keyword into whitelisted keywords.
    // The redux store filter that on 'tokenType' key.

    dispatch(
      projectActions.addKeywordToTopic({
        topicName: topicName,
        projectName: projectName,
        versionName: versionName,
        tokenType: tokenType,
        newTokenObj: {
          item: newNgramObj.ngram,
          matchType: matchTypeToAdd,
          count: newNgramObj.count,
          selected: false,
        },
      })
    );
  };

  // When the user add new ngram to topic, we update our init ngram array for pagination
  useEffect(() => {
    const filteredNgramsArray = props.topicStats[props.ngramType]?.filter(
      (item) =>
        !props.topicObj.attributedTokens?.find(
          (tokenObj) => tokenObj.item === item.ngram
        )
    );
    setInitNgramsArray(filteredNgramsArray);
    setMaxVerbatimCount(props.topicStats.count);
  }, [props.topicStats, props.topicObj.attributedTokens]);

  if (props.loading) {
    return (
      <div className={styles.container}>
        <div className={styles.title}>
          <span className={styles["box-title"]}>{props.title}</span>
        </div>
        <div
          className={styles["content-container"]}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span className={styles["box-title"]}>{props.title}</span>
      </div>
      <div className={styles["content-container"]}>
        {currentNgrams?.map((ngramObj, index) => (
          <div className={styles["ngram-row"]} key={index}>
            <div className={styles.token}>{ngramObj.ngram}</div>
            <div className={styles.ngrambar}>
              <div
                style={{
                  width: (ngramObj.count / maxVerbatimCount) * 100,
                }}
                className={styles["progress-bar"]}
              ></div>
              <div className={styles["ngram-count"]}>{ngramObj.count}</div>
            </div>
            <div className={styles["button-container"]}>
              <AddButton
                title="Add string matching"
                className={styles["custom-add-button"]}
                customStyle={{
                  color: "#e7be4d",
                }}
                onClick={() => addKeywordToTopic(ngramObj, "STRING")}
              />
              <AddButton
                title="Add exact matching"
                className={styles["custom-add-button"]}
                customStyle={{
                  color: "#a056ab",
                }}
                onClick={() => addKeywordToTopic(ngramObj, "WORD")}
              />
              <BlacklistButton
                title="Add into blacklist"
                onClick={() => addKeywordToTopic(ngramObj, "WORD", "blacklist")}
              />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <Pagination
          currentPage={currentPage}
          totalItemsNumber={totalNgrams}
          itemsPerPage={ngramsPerPage}
          paginate={paginate}
        />
      </div>
    </div>
  );
}

export default NgramList;
