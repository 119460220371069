import {
  faCheckCircle,
  faDownload,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";
import LoadingButton from "../../components/Button/LoadingButton";
import { BACKEND_URL_CONFIG } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import styles from "./FileUploader.module.css";

const FILE_TYPE_REF = {
  "text/csv": "csv",
  "application/vnd.ms-excel": "xlsx",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
};

function FileUploader(props) {
  const { currentUser } = useAuth();
  const [dragActive, setDragActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileExtension, setUploadedFileExtension] = useState(null);
  const inputRef = React.useRef(null);
  const navigate = useNavigate();

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setUploadedFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setUploadedFile(e.target.files[0]);
    }
  };

  const uploadFileHandler = async () => {
    if (uploadedFile.name === undefined) {
      alert("Choose a file !");
      return;
    }

    const formData = new FormData();
    formData.append("file", uploadedFile);

    const payload = {
      project_name: props.projectName,
      file_extension: uploadedFileExtension,
    };
    formData.append("json_data", JSON.stringify(payload));

    setLoading(true);
    setLoadingMsg("Loading...");
    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.uploadProjectFile,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        // If the file is uploaded, we trigger the process job
        triggerProcessProject();
      }
    } catch (err) {
      setIsFailed(true);
      setLoading(false);
    }
  };

  const triggerProcessProject = async () => {
    const payload = {
      project_name: props.projectName,
    };

    setLoading(true);
    setLoadingMsg("Process...");

    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.processProject,
        payload,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setLoading(false);

        // If all tasks are done, we redirect the client to project page
        // to create/choose a new version
        navigate(`/versions/${props.projectName}`);
        toast.success("The project is processed successfully.");
      }
    } catch (err) {
      toast.error("An error occured while processing project.");
      setLoading(false);
      setIsFailed(true);
    }
  };

  useEffect(() => {
    setIsFailed(false);
    const fileExtensionToSave = FILE_TYPE_REF[uploadedFile?.type.toLowerCase()];
    if (fileExtensionToSave) {
      setUploadedFileExtension(fileExtensionToSave);
    }
  }, [uploadedFile]);

  return (
    <>
      <div
        className={styles["upload-input-container"]}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        {uploadedFileExtension === "xlsx" && (
          <div className={styles["excel-file-alert-wrapper"]}>
            <>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{ color: "#2470d1", fontSize: "20px" }}
              />
              <span>
                <b>Attention: </b> If your excel file exceeds 1 million rows,
                please contact the administrator.
              </span>
            </>
          </div>
        )}
        <form
          className={styles["file-upload-form"]}
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            ref={inputRef}
            id={styles["input-file"]}
            type="file"
            accept=".xlsx, .xls, .csv"
            name="fileUpload"
            onChange={handleChange}
          />
          <label
            htmlFor={styles["input-file"]}
            id="file-drag"
            className={dragActive ? "drag-active" : ""}
          >
            {!uploadedFile ? (
              <div className={styles["upload-information-wrapper"]}>
                <FontAwesomeIcon
                  icon={faDownload}
                  className={styles["upload-file-icon"]}
                />
                <span style={{ textAlign: "center" }}>
                  Choose a file or drag it here
                </span>
              </div>
            ) : (
              <div className={styles["upload-information-wrapper"]}>
                {isFailed ? (
                  <>
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      style={{ color: "#d12424", fontSize: "320%" }}
                    />
                    <div className={styles["upload-message"]}>
                      <div style={{ textAlign: "center" }}>
                        <span style={{ fontWeight: "bold" }}>
                          {uploadedFile.name}
                        </span>{" "}
                        could not be processed. Please check your file !
                      </div>
                      <div style={{ textAlign: "center" }}>
                        To change, choose a new file or drag it again here.
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "green", fontSize: "50px" }}
                    />
                    <div className={styles["upload-message"]}>
                      <div style={{ textAlign: "center" }}>
                        <span style={{ fontWeight: "bold" }}>
                          {uploadedFile.name}
                        </span>{" "}
                        is received.
                      </div>
                      <div style={{ textAlign: "center" }}>
                        To change, choose a new file or drag it again here.
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </label>
        </form>
      </div>
      <div className={styles.footer}>
        {loading ? (
          <LoadingButton>{loadingMsg}</LoadingButton>
        ) : (
          <Button
            disabled={uploadedFile?.name === undefined}
            onClick={uploadFileHandler}
          >
            Upload file
          </Button>
        )}
      </div>
    </>
  );
}

export default FileUploader;
