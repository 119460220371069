import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./SaveButton.module.css";

function SaveButton(props) {
  // The className can be custom, so we check first if there is any props about that
  const className = props.className
    ? props.className
    : styles["save-icon-container"];

  return (
    <div
      className={className}
      onClick={props.onClick}
      style={props.customStyle}
    >
      <FontAwesomeIcon icon={faFloppyDisk} />
    </div>
  );
}

export default SaveButton;
