import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import styles from "./Navbar.module.css";

const Navbar = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const dropdownMenuRef = useRef("");

  const logoClickHandler = () => {
    navigate("/");
  };

  const dropdownMenuHandler = () => {
    const currentDisplayMode = dropdownMenuRef.current.style.display;
    if (currentDisplayMode === "flex") {
      dropdownMenuRef.current.style.display = null;
      return;
    }
    dropdownMenuRef.current.style.display = "flex";
  };

  return (
    <header className={styles["container"]}>
      <div className={styles.header}>
        <div className={styles["logo-container"]}>
          <img
            src={process.env.PUBLIC_URL + "/img/ctopic_logo.png"}
            className={styles.logo}
            onClick={logoClickHandler}
            alt=""
          ></img>
          {process.env.NODE_ENV === "development" && (
            <div className={styles["environment-info-container"]}>Demo</div>
          )}
        </div>
        <div className={styles.content}>
          <div
            className={styles["dropdown-menu"]}
            onClick={dropdownMenuHandler}
          >
            <div className={styles["user-info"]}>
              <img
                src={currentUser.photoURL}
                className={styles["user-avatar"]}
                alt=""
              ></img>
              <span>{currentUser?.displayName}</span>
            </div>
            <div ref={dropdownMenuRef} className={styles["dropdown-content"]}>
              <div className={styles["dropdown-item"]} onClick={logout}>
                <FontAwesomeIcon
                  icon={faSignOut}
                  style={{ fontSize: "15px" }}
                />
                Logout
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
