import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
  name: "projectSlice",
  initialState: {},
  reducers: {
    deleteVersion(state, action) {
      const { projectName, versionName } = action.payload;
      const project = state[projectName] || {};

      if (project[versionName]) {
        const { [versionName]: versionToDelete, ...restVersions } = project;
        const updatedProject = {
          ...restVersions,
        };

        // Créez un nouvel état sans modifier l'original
        return {
          ...state,
          [projectName]: updatedProject,
        };
      }

      // Si la version n'existe pas, retournez simplement l'état actuel
      return state;
    },
    addTopic(state, action) {
      const { topicName, projectName, versionName } = action.payload;

      const topicExists = (
        state[projectName]?.[versionName]?.allTopics || []
      ).some((topic) => topic.topicName === topicName);

      // If the topic already exist, we return state
      if (topicExists) {
        return state;
      }

      return {
        ...state,
        [projectName]: {
          ...state[projectName],
          [versionName]: {
            ...(state[projectName]?.[versionName] || []),
            allTopics: [
              ...(state[projectName]?.[versionName]?.allTopics || []),
              {
                topicName: topicName, // topic id
                attributedTokens: [],
                blacklistedTokens: [],
                matchedRawVerbatims: [],
                matchedCleanedVerbatims: [],
              },
            ],
          },
        },
      };
    },
    deleteTopic(state, action) {
      const { topicToDel, projectName, versionName } = action.payload;
      const project = state[projectName] || {};
      const version = project[versionName] || {};
      const allTopics = version.allTopics || [];

      const filteredTopics = allTopics.filter(
        (item) => item.topicName !== topicToDel
      );

      return {
        ...state,
        [projectName]: {
          ...project,
          [versionName]: {
            ...version,
            allTopics: filteredTopics,
          },
        },
      };
    },
    addKeywordToTopic(state, action) {
      const { topicName, projectName, versionName, newTokenObj, tokenType } =
        action.payload;
      const project = state[projectName] || {};
      const version = project[versionName] || {};
      const allTopics = version.allTopics || [];

      // Tokentype can be 'whitelist' or 'blacklist'.
      // We save these informations in our state:
      // For whitelisted tokens -> attributedTokens, blacklisted tokens -> blacklistedTokens
      const tokenArrayName =
        tokenType === "blacklist" ? "blacklistedTokens" : "attributedTokens";

      const filteredTopics = allTopics.map((topic) => {
        if (topic.topicName === topicName) {
          if (
            !topic[tokenArrayName].some(
              (tokenObj) => tokenObj.item === newTokenObj.item
            )
          ) {
            return {
              ...topic,
              [tokenArrayName]: [...topic[tokenArrayName], newTokenObj],
            };
          }
        }
        return topic;
      });

      return {
        ...state,
        [projectName]: {
          ...project,
          [versionName]: {
            ...version,
            allTopics: filteredTopics,
          },
        },
      };
    },
    deleteKeywordFromTopic(state, action) {
      const { topicName, projectName, versionName, tokenToDel, tokenType } =
        action.payload;
      const project = state[projectName] || {};
      const version = project[versionName] || {};
      const allTopics = version.allTopics || [];

      // Tokentype can be 'whitelist' or 'blacklist'.
      // We save these informations in our state:
      // For whitelisted tokens -> attributedTokens, blacklisted tokens -> blacklistedTokens
      const tokenArrayName =
        tokenType === "blacklist" ? "blacklistedTokens" : "attributedTokens";

      return {
        ...state,
        [projectName]: {
          ...project,
          [versionName]: {
            ...version,
            allTopics: allTopics.map((topic) => {
              if (topic.topicName === topicName) {
                return {
                  ...topic,
                  [tokenArrayName]: topic[tokenArrayName].filter(
                    (tokenObj) => tokenObj.item !== tokenToDel
                  ),
                };
              }
              return topic;
            }),
          },
        },
      };
    },
    updateKeyword(state, action) {
      const {
        topicName,
        projectName,
        versionName,
        tokenType,
        oldToken,
        newTokenObj,
      } = action.payload;
      const project = state[projectName] || {};
      const version = project[versionName] || {};
      const allTopics = version.allTopics || [];

      // Tokentype can be 'whitelist' or 'blacklist'.
      // We save these informations in our state:
      // For whitelisted tokens -> attributedTokens, blacklisted tokens -> blacklistedTokens
      const tokenArrayName =
        tokenType === "blacklist" ? "blacklistedTokens" : "attributedTokens";

      return {
        ...state,
        [projectName]: {
          ...project,
          [versionName]: {
            ...version,
            allTopics: allTopics.map((topic) => {
              if (topic.topicName === topicName) {
                return {
                  ...topic,
                  [tokenArrayName]: topic[tokenArrayName].map((tokenObj) => {
                    if (tokenObj.item === oldToken) {
                      return newTokenObj; // Replace old token with new token object
                    }
                    return tokenObj;
                  }),
                };
              }
              return topic;
            }),
          },
        },
      };
    },
    selectAllTokens(state, action) {
      const { topicName, projectName, versionName, tokenType, isSelected } =
        action.payload;
      const project = state[projectName] || {};
      const version = project[versionName] || {};
      const allTopics = version.allTopics || [];

      // Tokentype can be 'whitelist' or 'blacklist'.
      // We save these informations in our state:
      // For whitelisted tokens -> attributedTokens, blacklisted tokens -> blacklistedTokens
      const tokenArrayName =
        tokenType === "blacklist" ? "blacklistedTokens" : "attributedTokens";

      return {
        ...state,
        [projectName]: {
          ...project,
          [versionName]: {
            ...version,
            allTopics: allTopics.map((topic) => {
              if (topic.topicName === topicName) {
                const newTokensArray = topic[tokenArrayName].map((tokenObj) => {
                  return {
                    ...tokenObj,
                    selected: isSelected,
                  };
                });
                return {
                  ...topic,
                  [tokenArrayName]: newTokensArray,
                };
              }
              return topic;
            }),
          },
        },
      };
    },
    selectOneToken(state, action) {
      const {
        topicName,
        projectName,
        versionName,
        tokenType,
        isSelected,
        item,
      } = action.payload;
      const project = state[projectName] || {};
      const version = project[versionName] || {};
      const allTopics = version.allTopics || [];

      // Tokentype can be 'whitelist' or 'blacklist'.
      // We save these informations in our state:
      // For whitelisted tokens -> attributedTokens, blacklisted tokens -> blacklistedTokens
      const tokenArrayName =
        tokenType === "blacklist" ? "blacklistedTokens" : "attributedTokens";

      return {
        ...state,
        [projectName]: {
          ...project,
          [versionName]: {
            ...version,
            allTopics: allTopics.map((topic) => {
              if (topic.topicName === topicName) {
                const newTokensArray = topic[tokenArrayName].map((tokenObj) => {
                  if (tokenObj.item === item) {
                    return {
                      ...tokenObj,
                      selected: isSelected,
                    };
                  }
                  return tokenObj;
                });

                return {
                  ...topic,
                  [tokenArrayName]: newTokensArray,
                };
              }
              return topic;
            }),
          },
        },
      };
    },
    addTopicStats(state, action) {
      const { topicStats, projectName, versionName, topicName } =
        action.payload;

      const project = state[projectName] || {};
      const version = project[versionName] || {};
      const allTopics = version.allTopics || [];

      const filteredTopics = allTopics.map((topic) => {
        if (topic.topicName === topicName) {
          return {
            ...topic,
            topicStats: topicStats,
          };
        }
        return topic;
      });

      return {
        ...state,
        [projectName]: {
          ...project,
          [versionName]: {
            ...version,
            allTopics: filteredTopics,
          },
        },
      };
    },
    addUnclassifiedVerbatims(state, action) {
      // Add unclassified verbatims while processing version
      // The goal is to show all unclassified verbatims to the user on Project View Page.
      const {
        projectName,
        versionName,
        unclassifiedRawVerbatims,
        unclassifiedCleanedVerbatims,
      } = action.payload;

      const project = state[projectName] || {};
      const version = project[versionName] || {};

      return {
        ...state,
        [projectName]: {
          ...project,
          [versionName]: {
            ...version,
            unclassifiedRawVerbatims: unclassifiedRawVerbatims,
            unclassifiedCleanedVerbatims: unclassifiedCleanedVerbatims,
          },
        },
      };
    },
    addMatchedVerbatimsToTopic(state, action) {
      // Add matched verbatims to topics
      const {
        projectName,
        topicName,
        versionName,
        matchedRawVerbatims,
        matchedCleanedVerbatims,
      } = action.payload;

      const project = state[projectName] || {};
      const version = project[versionName] || {};
      const allTopics = version.allTopics || [];

      return {
        ...state,
        [projectName]: {
          ...project,
          [versionName]: {
            ...version,
            allTopics: allTopics.map((topic) => {
              if (topic.topicName === topicName) {
                return {
                  ...topic,
                  matchedRawVerbatims: matchedRawVerbatims,
                  matchedCleanedVerbatims: matchedCleanedVerbatims,
                };
              }
              return topic;
            }),
          },
        },
      };
    },
    addRegexSuggestionToTopic(state, action) {
      // Add suggested regex to topic in order to cache them.
      const { projectName, versionName, topicName, suggestedRegex } =
        action.payload;

      const project = state[projectName] || {};
      const version = project[versionName] || {};
      const allTopics = version.allTopics || [];

      return {
        ...state,
        [projectName]: {
          ...project,
          [versionName]: {
            ...version,
            allTopics: allTopics.map((topic) => {
              if (topic && topic.topicName === topicName) {
                return {
                  ...topic,
                  suggestedRegexArray: [
                    ...(topic.suggestedRegexArray || []),
                    suggestedRegex,
                  ],
                };
              }
              return topic;
            }),
          },
        },
      };
    },
    deleteRegexSuggestionFromTopic(state, action) {
      // Delete suggested regex from topic
      const { projectName, versionName, topicName, suggestionToDel } =
        action.payload;

      const project = state[projectName] || {};
      const version = project[versionName] || {};
      const allTopics = version.allTopics || [];

      return {
        ...state,
        [projectName]: {
          ...project,
          [versionName]: {
            ...version,
            allTopics: allTopics.map((topic) => {
              if (topic && topic.topicName === topicName) {
                return {
                  ...topic,
                  suggestedRegexArray: topic.suggestedRegexArray.filter(
                    (suggestion) => suggestion !== suggestionToDel
                  ),
                };
              }
              return topic;
            }),
          },
        },
      };
    },
  },
});

export const projectActions = projectSlice.actions;

export default projectSlice;
