import styles from "./Pagination.module.css";

function Pagination(props) {
  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(props.totalItemsNumber / props.itemsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className={styles.pagination}>
        {pageNumbers.map((number) => (
          <li
            onClick={() => props.paginate(number)}
            key={number}
            className={`${styles["page-item"]} ${
              props.currentPage === number ? styles["page-selected"] : ""
            }`}
          >
            <span className={styles["page-link"]}>{number}</span>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Pagination;
