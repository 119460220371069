import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import styles from "./LoginLayout.module.css";

const LoginLayout = () => {
  const { login, currentUser } = useAuth();

  if (currentUser !== null) {
    toast.info("You're already logged in.");
    return <Navigate to="/" replace={true} />;
  }
  return (
    <Fragment>
      <main className={styles["container"]}>
        <div className={styles["login-wrapper"]}>
          <div className={styles["left-side"]}>
            <img
              src={process.env.PUBLIC_URL + "/img/ctopic_logo.png"}
              className={styles["logo-wrapper"]}
              alt=""
            ></img>
          </div>
          <div className={styles["right-side"]}>
            <div className={styles["welcome-text"]}>
              <span className={styles["greetings-text"]}>Hi there !</span>
              <span>Welcome to C-Topic Tool !</span>
              <span>
                Please login with your <b>Google</b> account.
              </span>
            </div>
            <div className={styles["button-wrapper"]}>
              <button onClick={login} className={styles["login-button"]}>
                Sign in with <FontAwesomeIcon icon={faGoogle} />
              </button>
            </div>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default LoginLayout;
