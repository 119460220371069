import {
  faCircleQuestion,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import Pagination from "../../components/Pagination/Pagination";
import Tag from "../../components/Tag/Tag";
import { useProject } from "../../hooks/useProject";
import styles from "./TopicOverlapTable.module.css";

function TopicOverlapTable({ contentBoxTitle }) {
  const [currentPage, setCurrentPage] = useState(1);
  const popupInformationRef = useRef(0);
  const { versionData } = useProject();
  const percentageExplicationContent =
    "Overlap percentage: X% of the verbatims in the corpus are classified in topic_Y and in topic_Z";

  const topicPairsPerPage = 5;
  const filteredOverlapArray = versionData?.stats.topic_overlap_percentage
    .filter((item) => item.overlap_percentage > 0)
    .sort((a, b) => b.overlap_percentage - a.overlap_percentage);
  const totalTopicOverlapNb = filteredOverlapArray.length;

  const indexOfLastNgram = currentPage * topicPairsPerPage;
  const indexOfFirstNgram = indexOfLastNgram - topicPairsPerPage;
  const currentTopicPairs = filteredOverlapArray?.slice(
    indexOfFirstNgram,
    indexOfLastNgram
  );

  const popupInformationHandler = () => {
    const currentDisplayMode = popupInformationRef.current.style.display;
    if (currentDisplayMode === "flex") {
      popupInformationRef.current.style.display = null;
      return;
    }
    popupInformationRef.current.style.display = "flex";
  };

  const popupInformationClose = () => {
    popupInformationRef.current.style.display = null;
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const containerClassName =
    totalTopicOverlapNb === 0
      ? `${styles.content} ${styles["no-min-height"]}`
      : styles.content;

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span className={styles["title-text"]}>{contentBoxTitle}</span>
        <div
          style={{
            position: "relative",
          }}
        >
          <div
            ref={popupInformationRef}
            className={styles["popup-information"]}
          >
            {percentageExplicationContent}
          </div>
          <div className={styles["switch-wrapper"]}>
            <FontAwesomeIcon
              icon={faCircleQuestion}
              className={styles["information-icon"]}
              onMouseEnter={() => popupInformationHandler()}
              onMouseLeave={() => popupInformationClose()}
            ></FontAwesomeIcon>
          </div>
        </div>
      </div>
      <div className={containerClassName}>
        {currentTopicPairs.length === 0 ? (
          <div className={styles["empty-table-wrapper"]}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{ color: "#a60909", fontSize: "40px" }}
            />
            <span>There are no overlapping topic pairs to display.</span>
          </div>
        ) : (
          <>
            <table id={styles["overlap-table"]}>
              <thead>
                <tr>
                  <th scope="col">Topic 1</th>
                  <th scope="col">Topic 2</th>
                  <th scope="col">Overlap percentange</th>
                </tr>
              </thead>
              <tbody>
                {currentTopicPairs.map((overlapObj, index) => (
                  <tr key={index}>
                    <th>{overlapObj.topic_pair[0]}</th>
                    <td>{overlapObj.topic_pair[1]}</td>
                    <td>
                      <Tag bgColor="#759185" fontSize="12px">
                        {(overlapObj.overlap_percentage * 100).toFixed(2)}%
                      </Tag>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className={styles.footer}>
              <Pagination
                currentPage={currentPage}
                totalItemsNumber={totalTopicOverlapNb}
                itemsPerPage={topicPairsPerPage}
                paginate={paginate}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default TopicOverlapTable;
