import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AddButton from "../../../components/Button/AddButton";
import BlacklistButton from "../../../components/Button/BlacklistButton";
import DeleteButton from "../../../components/Button/DeleteButton";
import LoadingButton from "../../../components/Button/LoadingButton";
import PercentageCircle from "../../../components/PercentageCircle/PercentageCircle";
import Tag from "../../../components/Tag/Tag";
import { BACKEND_URL_CONFIG } from "../../../config";
import { db } from "../../../firebase/firebase";
import { useAuth } from "../../../hooks/useAuth";
import { useProject } from "../../../hooks/useProject";
import styles from "./AssistantResultsTable.module.css";

function AssistantResultsTable(props) {
  const {
    projectName,
    versionName,
    topicName,
    versionData,
    assistantRequested,
    setAssistantRequested,
  } = useProject();
  const { currentUser } = useAuth();
  const [assistantData, setAssistantData] = useState([]);

  const colorConfigRef = {
    synonyms: "#a95c2f",
    plurals: "#84457e",
    mispellings: "#a10909",
  };

  const structureAssistantData = (assistantDataInput) => {
    // The goal is to convert array type data into JS object data.
    const outputObj = {};
    for (const key in assistantDataInput) {
      if (Array.isArray(assistantDataInput[key].synonyms)) {
        outputObj[key] = assistantDataInput[key].synonyms;
      }
    }
    return outputObj;
  };
  const fetchKeywordSimilarities = (assistantDataInput) => {
    toast.info(
      "We are enriching your synonym collection with a percentage of similarities..."
    );

    // Structure synonym data
    const structuredSynonymData = structureAssistantData(assistantDataInput);
    const payload = {
      project_name: projectName,
      version_name: versionName,
      topic_name: topicName,
      items_to_compare: structuredSynonymData,
    };

    axios
      .post(BACKEND_URL_CONFIG.processSimilarities, payload, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Similarities are calculated successfully.");
        }
      })
      .catch((err) => {
        toast.error(
          "An error occured while calculating the synonyms similarities."
        );
      });
  };

  const deleteSuggestionHandler = (
    tokenOrigin,
    suggestionsArray,
    suggestionToDel
  ) => {
    const docRef = doc(db, "projects", projectName);

    // Filter array without the item to delete
    const filteredSuggestionsArray = suggestionsArray.filter(
      (suggestion) => suggestion !== suggestionToDel
    );
    // So we update our doc
    updateDoc(docRef, {
      [`versions.${versionName}.topics.${topicName}.assistant.${tokenOrigin}.${props.assistantType}`]:
        filteredSuggestionsArray,
    });
  };

  useEffect(() => {
    if (assistantRequested === true) {
      if (versionData.topics[topicName]?.assistant) {
        fetchKeywordSimilarities(versionData.topics[topicName]?.assistant);
        setAssistantRequested(false);
      }
    }
  }, [assistantRequested]);

  useEffect(() => {
    const unsortedAssistantData = versionData.topics[topicName].assistant;
    const sortedAssistantData = Object.keys(unsortedAssistantData)
      .sort()
      .reduce(function (acc, key) {
        acc[key] = unsortedAssistantData[key];
        return acc;
      }, {});
    setAssistantData(sortedAssistantData);
  }, [versionData]);

  return (
    <>
      {assistantData && Object.keys(assistantData).length > 0 ? (
        <div className={styles["table-container"]}>
          <table id={styles["assistant-suggestion-table"]}>
            <thead>
              <tr>
                <th>#</th>
                <th>Token</th>
                <th>Suggestion</th>
                {/* If it's about similarities, we add similarities percentage column */}
                {props.assistantType === "synonyms" && <th>Similarity</th>}
                <th>Assistant type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(assistantData)?.map(
                ([tokenOrigin, suggestionsObj]) =>
                  suggestionsObj?.[props.assistantType]?.map(
                    (eachSuggestion, index) =>
                      // If the new suggestion exists in attributedToken, we hide the token
                      !props.topicObj.attributedTokens?.some(
                        (tokenObj) => tokenObj.item === eachSuggestion
                      ) && (
                        <tr key={index} className={styles.row}>
                          <th>{index + 1}</th>
                          <th>{tokenOrigin}</th>
                          <th>{eachSuggestion}</th>
                          {/* If it's about similarities, we add similarities percentage cercle */}
                          {props.assistantType === "synonyms" && (
                            <th>
                              <div className={styles["icon-wrapper"]}>
                                {
                                  suggestionsObj.similarities?.find(
                                    (obj) => obj.synonym === eachSuggestion
                                  )?.similarities
                                }
                                {/* 
                                If the similarities does not exist for this keyword,
                                we put loading button.
                                Otherwise, similarities percentage component.
                                */}
                                {!suggestionsObj.similarities?.find(
                                  (obj) => obj.synonym === eachSuggestion
                                )?.similarity ? (
                                  <LoadingButton
                                    backgroundColor={"white"}
                                    iconCustomStyle={{ color: "black" }}
                                  />
                                ) : (
                                  <PercentageCircle
                                    percentage={Math.round(
                                      suggestionsObj.similarities?.find(
                                        (obj) => obj.synonym === eachSuggestion
                                      )?.similarity * 100
                                    )}
                                    textSize="10px"
                                  />
                                )}
                              </div>
                            </th>
                          )}
                          <th>
                            <div className={styles["icon-wrapper"]}>
                              <Tag
                                bgColor={colorConfigRef[props.assistantType]}
                              >
                                {props.assistantType}
                              </Tag>
                            </div>
                          </th>
                          <th>
                            <div className={styles["icon-wrapper"]}>
                              <AddButton
                                title="Add string matching"
                                customStyle={{
                                  color: "#e7be4d",
                                }}
                                onClick={() =>
                                  props.addKeywordToTopic(
                                    eachSuggestion,
                                    "STRING"
                                  )
                                }
                              />
                              <AddButton
                                title="Add exact matching"
                                customStyle={{
                                  color: "#a056ab",
                                }}
                                onClick={() =>
                                  props.addKeywordToTopic(
                                    eachSuggestion,
                                    "WORD"
                                  )
                                }
                              />
                              <BlacklistButton
                                title="Add into blacklist"
                                onClick={() =>
                                  props.addKeywordToTopic(
                                    eachSuggestion,
                                    "STRING",
                                    "blacklist"
                                  )
                                }
                              />
                              <DeleteButton
                                customStyle={{ margin: "0" }}
                                onClick={() =>
                                  deleteSuggestionHandler(
                                    tokenOrigin,
                                    suggestionsObj[props.assistantType],
                                    eachSuggestion
                                  )
                                }
                              />
                            </div>
                          </th>
                        </tr>
                      )
                  )
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div className={styles["empty-table-wrapper"]}>
          <FontAwesomeIcon
            icon={faExclamationCircle}
            style={{ color: "#a60909", fontSize: "40px" }}
          />
          <span>
            There are no suggestions.
            <br />
            Please select your keywords and then click on the button you wish to
            have assistance
          </span>
        </div>
      )}
    </>
  );
}

export default AssistantResultsTable;
