import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import styles from "./404.module.css";
function NotFound() {
  const navigate = useNavigate();
  const goToHomePage = () => {
    navigate("");
  };
  return (
    <div className={styles.container}>
      <div className={styles["error-message-wrapper"]}>
        <div className={styles["not-found-image-wrapper"]}>
          <img
            src={process.env.PUBLIC_URL + "/img/not-found.gif"}
            className={styles["not-found-gif"]}
            alt=""
          ></img>
        </div>
        <div className={styles["message-container"]}>
          <span className={styles["not-found-error-code"]}>ERROR 404</span>
          <span className={styles["not-found-message"]}>PAGE NOT FOUND</span>
          <div className={styles["go-back-button"]}>
            <Button onClick={goToHomePage}>
              <FontAwesomeIcon icon={faArrowLeft} /> Back to home
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
