import { doc, onSnapshot } from "firebase/firestore";
import { createContext, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { db } from "../firebase/firebase";
import { useAuth } from "../hooks/useAuth";

export const ProjectContext = createContext();

const ProjectProvider = () => {
  const { currentUser } = useAuth();
  const [projectData, setProjectData] = useState(null);
  const [versionData, setVersionData] = useState(null);
  const [projectStats, setProjectStats] = useState({});
  const [assistantRequested, setAssistantRequested] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [loading, setLoading] = useState(true);
  const urlParams = useParams();
  const projectName = urlParams.projectName;
  const versionName = urlParams.versionName;
  const topicName = urlParams.topicName;

  useEffect(() => {
    // Connection to db with real time option
    const docRef = doc(db, "projects", projectName);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      const projectDataObj = doc.data();
      if (projectDataObj !== undefined) {
        // Save project data into state
        setProjectData(projectDataObj);

        // Extract project stats
        setProjectStats({
          top_1grams: projectDataObj.stats.top_1grams,
          top_2grams: projectDataObj.stats.top_2grams,
          count: projectDataObj.stats.verbatim_count,
        });

        // Extract version data
        const versionData = projectDataObj.versions[versionName];
        if (versionData !== undefined) {
          // Save version data into state
          setVersionData(versionData);

          // Check if the user is owner of version
          // (that's mean, if the version is locked by him/her)
          const locked = versionData.locked;
          const lockedBy = versionData.locked_by;
          if (locked) {
            setIsOwner(lockedBy === currentUser.email);
          }
        }
        setLoading(false);
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    projectName,
    versionName,
    topicName,
    projectData,
    versionData,
    projectStats,
    isOwner,
    assistantRequested,
    setAssistantRequested,
  };

  return (
    <ProjectContext.Provider value={value}>
      {!loading && <Outlet />}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
