let initPreprocessConfig = {
  lower_process: false,
  remove_stopword: false,
  lemmatize_process: false,
  standardization_process: false,
  remove_middle_point: false,
  remove_url: false,
  remove_mail: false,
  remove_user_mention: false,
  remove_hashtag_sign: false,
  remove_hashtag_word: false,
  remove_number: false,
  remove_currency: false,
  remove_punctuation: false,
  remove_emoji: false,
  demojize_emoji: false,
};

let preprocessInformationsObject = {
  lower_process: {
    displayName: "Lower",
    description: "It will convert all text to lowercase.",
  },
  lemmatize_process: {
    displayName: "Lemmatization",
    description: "It will enable lemmatization mode in preprocess.",
  },
  standardization_process: {
    displayName: "Standardization",
    description: `This process will transform all characters into ASCII characters.
    That means it will remove all accents and special characters.
    For more information, please take a look at what ASCII characters are.`,
  },
  remove_stopword: {
    displayName: "Remove stopword",
    description: "It will remove stopwords from the text.",
  },
  remove_middle_point: {
    displayName: "Remove middle point",
    description: "It will remove middle points from the text.",
  },
  remove_url: {
    displayName: "Remove URLs",
    description: "It will remove URLs from the text.",
  },
  remove_mail: {
    displayName: "Remove mails",
    description: "It will remove email addresses from the text.",
  },
  remove_user_mention: {
    displayName: "Remove user mention",
    description: "It will remove user mentions from the text.",
  },
  remove_hashtag_sign: {
    displayName: "Remove hashtag sign",
    description: "It will remove hashtag signs from the text.",
  },
  remove_hashtag_word: {
    displayName: "Remove hashtag word",
    description: "It will remove hashtag words from the text.",
  },
  remove_number: {
    displayName: "Remove numbers",
    description: "It will remove numbers from the text.",
  },
  remove_punctuation: {
    displayName: "Remove punctuations",
    description: "It will remove punctuations from the text.",
  },
  remove_currency: {
    displayName: "Remove currency",
    description: "It will remove all currency signs from the text.",
  },
  remove_emoji: {
    displayName: "Remove emojis",
    description: "It will remove emojis from the text.",
  },
  demojize_emoji: {
    displayName: "Demojize all emojis",
    description: "It will convert emojis to their textual representation.",
  },
};

const languagesOptions = [
  { value: "fr", label: "French" },
  { value: "en", label: "English" },
];

const tokenizerOptions = [
  { value: "manual", label: "Manual" },
  { value: "spacy", label: "Spacy" },
];

const lemmatizerOptions = [
  { value: "iramuteq", label: "Iramuteq", compatible: ["manual", "spacy"] },
  { value: "spacy", label: "Spacy", compatible: ["spacy"] },
];

const splitterChars = [
  " ",
  ".",
  "?",
  "!",
  ",",
  ";",
  ":",
  '"',
  "(",
  ")",
  "{",
  "}",
  "[",
  "]",
  "|",
];
const convertedSplitterChars = splitterChars.map((char) => ({
  value: char,
  label: char === " " ? "whitespace" : char,
}));

export {
  languagesOptions,
  convertedSplitterChars,
  tokenizerOptions,
  lemmatizerOptions,
  initPreprocessConfig,
  preprocessInformationsObject,
};
