import styles from "./Button.module.css";

function Button(props) {
  const buttonClassName = props.className
    ? props.className
    : styles["primary-button"];
  return (
    <>
      <button
        onClick={props.onClick}
        className={`${
          props.disabled ? styles["disabled-button"] : buttonClassName
        }`}
        style={props.style}
        disabled={props.disabled}
        title={props.title}
      >
        {props.children}
      </button>
    </>
  );
}

export default Button;
