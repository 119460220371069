import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AddButton from "../../../components/Button/AddButton";
import BlacklistButton from "../../../components/Button/BlacklistButton";
import Button from "../../../components/Button/Button";
import DeleteButton from "../../../components/Button/DeleteButton";
import LoadingButton from "../../../components/Button/LoadingButton";
import { BACKEND_URL_CONFIG } from "../../../config";
import { useAuth } from "../../../hooks/useAuth";
import { useProject } from "../../../hooks/useProject";
import { projectActions } from "../../../store/project-slice";
import styles from "./RegexBuilder.module.css";

function RegexBuilder(props) {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const { projectName, versionName, topicName } = useProject();
  const [loading, setLoading] = useState();
  const [regexQuery, setRegexQuery] = useState(null);
  const regexQueryInputRef = useRef();

  // The goal is to extract all suggested regex from redux store.
  // Because we cache them.
  // Important re-render issue :
  // https://redux.js.org/usage/deriving-data-selectors#optimizing-selectors-with-memoization
  const suggestedRegexArrays = useSelector((state) => {
    const { allTopics } = state.project[projectName]?.[versionName] || {};
    const filteredTopic = allTopics?.find(
      (topic) => topic.topicName === topicName
    );
    return filteredTopic?.suggestedRegexArray;
  });

  const regexQueryChangeHandler = () => {
    setRegexQuery(regexQueryInputRef.current.value);
  };

  const submitButtonHandler = () => {
    sendRequest();
  };

  const regexQueryKeyDownListener = (event) => {
    if (event.key === "Enter") {
      sendRequest();
    }
  };

  const addKeywordToTopic = (newToken, matchTypeToAdd, tokenType) => {
    // So there are two options. The first one is to add keyword into blacklisted keywords
    // The second one is about adding keyword into whitelisted keywords.
    // The redux store filter that on 'tokenType' key.

    dispatch(
      projectActions.addKeywordToTopic({
        topicName: topicName,
        projectName: projectName,
        versionName: versionName,
        tokenType: tokenType,
        newTokenObj: {
          item: newToken,
          matchType: matchTypeToAdd,
          count: null,
          selected: false,
        },
      })
    );
  };

  const deleteRegexSuggestionHandler = (suggestionToDel) => {
    dispatch(
      projectActions.deleteRegexSuggestionFromTopic({
        projectName: projectName,
        versionName: versionName,
        topicName: topicName,
        suggestionToDel: suggestionToDel,
      })
    );
  };

  const sendRequest = async () => {
    if (regexQuery === null || regexQuery === "") {
      toast.error("The regex query input can not be empty.");
      return;
    }
    const payload = {
      regex_request: regexQuery,
    };

    setLoading(true);
    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.regexBuilder,
        payload,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setLoading(false);
        dispatch(
          projectActions.addRegexSuggestionToTopic({
            projectName: projectName,
            versionName: versionName,
            topicName: topicName,
            suggestedRegex: response.data.built_regex,
          })
        );
        toast.success("Your request is processed successfully.");
        regexQueryInputRef.current.value = "";
      }
    } catch (err) {
      toast.error("An error occured while requesting OpenAI to build a regex.");
      setLoading(false);
    }
  };

  return (
    <div className={styles["regex-builder-container"]}>
      <div className={styles["input-wrapper"]}>
        <input
          type="text"
          id={styles["regex-query-input"]}
          ref={regexQueryInputRef}
          onChange={regexQueryChangeHandler}
          placeholder="Please type your query to get your regex..."
          onKeyDown={regexQueryKeyDownListener}
        />
        {loading ? (
          <LoadingButton />
        ) : (
          <Button onClick={submitButtonHandler}>
            <FontAwesomeIcon icon={faPaperPlane} />
          </Button>
        )}
      </div>
      <div className={styles["regex-builder-results-container"]}>
        {suggestedRegexArrays?.map((eachSuggestion, index) => (
          <div key={index} className={styles["regex-suggestion-row"]}>
            <div className={styles["regex"]}>{eachSuggestion}</div>
            <div className={styles["action-buttons-wrapper"]}>
              <AddButton
                title="Add into whitelist"
                className={styles["custom-add-button"]}
                customStyle={{
                  color: "#115f03c4",
                }}
                onClick={() => addKeywordToTopic(eachSuggestion, "REGEX")}
              />
              <BlacklistButton
                title="Add into blacklist"
                onClick={() =>
                  addKeywordToTopic(eachSuggestion, "REGEX", "blacklist")
                }
              />
              <DeleteButton
                customStyle={{ margin: "0" }}
                onClick={() => deleteRegexSuggestionHandler(eachSuggestion)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RegexBuilder;
