import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./LoadingButton.module.css";

function LoadingButton(props) {
  const backgroundColor = props.backgroundColor ? props.backgroundColor : null;
  const iconCustomStyle = props.iconCustomStyle ? props.iconCustomStyle : null;
  return (
    <>
      <button
        className={styles["loading-button"]}
        style={{ backgroundColor: backgroundColor }}
      >
        <FontAwesomeIcon
          icon={faSpinner}
          className="fa-spin"
          style={iconCustomStyle}
        />{" "}
        {props.children}
      </button>
    </>
  );
}

export default LoadingButton;
