import styles from "./Tag.module.css";
function Tag(props) {
  return (
    <div
      className={styles["tag-content"]}
      style={{
        backgroundColor: props.bgColor,
        color: props.color,
        fontSize: props.fontSize,
      }}
    >
      {props.children}
    </div>
  );
}

export default Tag;
