import {
  faExclamationCircle,
  faFileCirclePlus,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import AddButton from "../../components/Button/AddButton";
import LoadingButton from "../../components/Button/LoadingButton";
import TransparentButton from "../../components/Button/TransparentButton";
import Pagination from "../../components/Pagination/Pagination";
import { BACKEND_URL_CONFIG } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import { useProject } from "../../hooks/useProject";
import styles from "./TopicBrowser.module.css";
import TopicItem from "./TopicItem";

const noTopicsMessage = "There is no topics. Please create new one.";

function TopicBrowserBox(props) {
  const { projectName, versionName } = useProject();
  const { currentUser } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const topicsPerPage = props.topicsPerPage ? props.topicPerPage : 5;
  const indexOfLastTopic = currentPage * topicsPerPage;
  const indexOfFirstTopic = indexOfLastTopic - topicsPerPage;
  const topicsData = props.topicsData ? props.topicsData : [];
  const totalTopicNumber = topicsData.length;
  // filtered topics array for pagination
  const filteredTopics = topicsData.slice(indexOfFirstTopic, indexOfLastTopic);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const addTopicHandler = async () => {
    const payload = {
      project_name: projectName,
      version_name: versionName,
    };
    setLoading(true);
    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.createTopic,
        payload,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setLoading(false);
      }
    } catch (err) {
      toast.error("An error occured while adding new topic");
      setLoading(false);
    }
  };

  const openTopicExportModal = () => {
    if (topicsData.length === 0) {
      toast.error("There is no topics in your version");
      return;
    }
    props.setModalType("export");
    props.setModalEnabled(true);
  };

  const openTopicImportModal = () => {
    props.setModalType("import");
    props.setModalEnabled(true);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          <span className={styles["title-text"]}>{props.contentBoxTitle}</span>
          <div className={styles["button-container"]}>
            {loading ? (
              <LoadingButton
                backgroundColor={"white"}
                iconCustomStyle={{ color: "black" }}
              />
            ) : (
              <>
                <AddButton onClick={addTopicHandler} />
                <TransparentButton
                  onClick={openTopicImportModal}
                  iconName={faFileCirclePlus}
                  title="Import topics"
                ></TransparentButton>
                <TransparentButton
                  onClick={openTopicExportModal}
                  iconName={faFileExport}
                  title="Export topics"
                ></TransparentButton>
              </>
            )}
          </div>
        </div>
        {filteredTopics.length !== 0 ? (
          <div className={styles.content}>
            {filteredTopics.map((topicObj) =>
              Object.entries(topicObj).map(([topicName, topicInfo], index) => (
                <TopicItem
                  topicName={topicName}
                  topicInfo={topicInfo}
                  setLoading={setLoading}
                  key={index}
                >
                  {topicName}
                </TopicItem>
              ))
            )}
          </div>
        ) : (
          <div className={styles["no-topic-wrapper"]}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={{ color: "#a60909", fontSize: "40px" }}
            />
            <span>{noTopicsMessage}</span>
          </div>
        )}
        <div className={styles.footer}>
          <Pagination
            currentPage={currentPage}
            totalItemsNumber={totalTopicNumber}
            itemsPerPage={topicsPerPage}
            paginate={paginate}
          />
        </div>
      </div>
    </>
  );
}

export default TopicBrowserBox;
