import styles from "./GoBackButton.module.css";

function GoBackButton(props) {
  return (
    <>
      <button
        onClick={props.onClick}
        className={styles["goback-button"]}
        style={props.style}
        disabled={props.disabled}
      >
        {props.children}
      </button>
    </>
  );
}

export default GoBackButton;
