import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./RefreshButton.module.css";

function RefreshButton(props) {
  return (
    <>
      <button className={styles["refresh-button"]} onClick={props.onClick}>
        <FontAwesomeIcon icon={faRefresh} />
      </button>
    </>
  );
}

export default RefreshButton;
