import React from "react";
import styles from "./PercentageCircle.module.css";

function PercentageCircle(props) {
  return (
    <div
      className={styles["circle-chart"]}
      style={{ width: props.circleSize ? props.circleSize : "35px" }}
    >
      <svg viewBox="0 0 36 36">
        <path
          className={styles["circle-bg"]}
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className={styles["circle"]}
          style={{ stroke: props.circleColor ? props.circleColor : "#7204bc" }}
          strokeDasharray={`${props.percentage}, 100`}
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text
          x="18"
          y="20.35"
          className={styles["percentage-text"]}
          style={{ fontSize: props.textSize ? props.textSize : "10px" }}
        >
          {props.percentage}%
        </text>
      </svg>
    </div>
  );
}

export default PercentageCircle;
